// Customizable Area Start
import React, { useContext } from "react";
import "../Common/TopSongs.web.css";
import "./label.web.css"
import { Grid, Box, Hidden } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { playcountP, loopIc, chatPlaylist, down, iconUp, expandMore, playlistProfile,player_Banner } from "blocks/dashboard/src/assets";
import millify from "millify";
import { Col, Row } from "antd";
import LineChart from "components/src/CustomChart/PopUps/LineCharts.web";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";


const LabelTopSongs = ({  beatList, audioPlay, trackName, playPlaylist, handleplay, handlePlayLists, isMusicButton }: any) => {

    const songsList = [
        {
            "id": "1212",
            "type": "playlist",
            "attributes": {
                "id": 1212,
                "status": "public_playlist",
                "play_list_type": "audio",
                "repost_count": 101,
                "play_count": 5,
                "created_at": "2023-08-18T05:58:44.815Z",
                "updated_at": "2023-08-31T10:04:01.102Z",
                "name": "add track",
                "playlist_items": {
                    "data": [
                        {
                            "id": "2201",
                            "type": "track_file",
                            "attributes": {
                                "title": "Kooch Na Karin",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcGdkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--7ca4480e3aed2ddfaaebc6c14306ffd91b1f10b2/tomp3.cc%20-%20Selena%20Gomez%20%20Come%20%20Get%20It.mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcGtkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f14fe9234074abdac2aba24b42a805372fd24259/download%20(6).jfif",
                                "artist_name": "demo user",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcnNlIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00e54b153ce1ac03fbbbcb750769110cacf5c4d2/image",
                                "play_count": 4500,
                                "comments_count": 37,
                                "repost_count": 18
                            }
                        },
                        {
                            "id": "2192",
                            "type": "track_file",
                            "attributes": {
                                "title": "nxzsound",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmNkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bd6c0d4d146845cac7f7818db3c93d979536fb8c/wuxd0toau3e1rhoat4ac5httpw90%20(2).mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmdkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--09d565485ba915bc8e10b18f91f2639be0ba8b35/download%20(2).jfif",
                                "artist_name": "finalplan",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdFFiIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2320ab68949fa949f4f60e4bb4800b488acdfb37/0acf6786ffde317cd6d2d6e386114420.jpg",
                                "play_count": 984,
                                "comments_count": 91,
                                "repost_count": 70
                            }
                        },
                        {
                            "id": "2234",
                            "type": "track_file",
                            "attributes": {
                                "title": "barsaat",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa1FlIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e80d114d04609bdb4c69c81134840e2ce23379a4/tomp3.cc%20-%20Barsaat%20Ki%20Dhun%20Song%20%20Rochak%20K%20Ft%20Jubin%20N%20%20Gurmeet%20C%20Karishma%20S%20Rashmi%20V%20%20Ashish%20P%20%20Bhushan%20K.mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa1VlIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--76366d0f74032f83791d7f03f3df946f8c20133b/download.jfif",
                                "artist_name": "nehatest",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1da800fb24a6a1d0888e5515dc64725c1787c942/african-lion-2888519_1280.jpg",
                                "play_count": 1433,
                                "comments_count": 60,
                                "repost_count": 13
                            }
                        }
                    ]
                },
                "cover_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbXdpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cf83f509d45add6c81450291b97aa676a715bc95/Screenshot%202023-08-17%20at%209.17.06%20PM.png",
                "avatar": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1da800fb24a6a1d0888e5515dc64725c1787c942/african-lion-2888519_1280.jpg",
                "artist_id": 2115,
                "artist_name": "nehatest",
                "followed_by_current_user": false,
                "follow_status": false,
                "user_type": "Artist",
                "song_count": 3,
                "following_count": 16,
                "followers_count": 11,
                "comments_count": 188,
                "arrow": false
            }
        },
        {
            "id": "1208",
            "type": "playlist",
            "attributes": {
                "id": 1208,
                "status": "private_playlist",
                "play_list_type": "audio",
                "repost_count": 75,
                "play_count": 1,
                "created_at": "2023-08-17T10:44:45.293Z",
                "updated_at": "2023-08-31T10:04:01.033Z",
                "name": "farheen t",
                "playlist_items": {
                    "data": [
                        {
                            "id": "2192",
                            "type": "track_file",
                            "attributes": {
                                "title": "nxzsound",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmNkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bd6c0d4d146845cac7f7818db3c93d979536fb8c/wuxd0toau3e1rhoat4ac5httpw90%20(2).mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmdkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--09d565485ba915bc8e10b18f91f2639be0ba8b35/download%20(2).jfif",
                                "artist_name": "finalplan",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdFFiIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2320ab68949fa949f4f60e4bb4800b488acdfb37/0acf6786ffde317cd6d2d6e386114420.jpg",
                                "play_count": 984,
                                "comments_count": 91,
                                "repost_count": 70
                            }
                        },
                        {
                            "id": "2246",
                            "type": "track_file",
                            "attributes": {
                                "title": "Aye jindgai",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbDBlIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cef8ddbea8110ea757c95f34469c56b6a52a39ab/tomp3.cc%20-%20Aye%20Zindagi%20%20Official%20Song%20%20Aakanksha%20Sharma%20%20Rishabh%20Srivastava%20%20Specials%20by%20Zee%20Music%20Co.mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbDRlIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3dc2b9401e7d38d48b5389611de49ec16ab359b2/maxresdefault%20(2).jpg",
                                "artist_name": "neew",
                                "artist_image": null,
                                "play_count": 181,
                                "comments_count": 10,
                                "repost_count": 5
                            }
                        }
                    ]
                },
                "cover_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbUlpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d42f38ff0f82ea9dc7fd42fc2a8b817a7833e587/Screenshot%202023-08-16%20at%205.11.58%20PM.png",
                "avatar": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1da800fb24a6a1d0888e5515dc64725c1787c942/african-lion-2888519_1280.jpg",
                "artist_id": 2115,
                "artist_name": "nehatest",
                "followed_by_current_user": false,
                "follow_status": false,
                "user_type": "Artist",
                "song_count": 2,
                "following_count": 16,
                "followers_count": 11,
                "comments_count": 101,
                "arrow": false
            }
        },
        {
            "id": "1201",
            "type": "playlist",
            "attributes": {
                "id": 1201,
                "status": "public_playlist",
                "play_list_type": "audio",
                "repost_count": 0,
                "play_count": 0,
                "created_at": "2023-08-10T03:41:56.792Z",
                "updated_at": "2023-08-10T03:41:56.804Z",
                "name": "t",
                "playlist_items": {
                    "data": []
                },
                "cover_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbElpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--985c616dec3a627b8a9b93584eadeea29845fcaf/african-lion-2888519_1280.jpg",
                "avatar": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1da800fb24a6a1d0888e5515dc64725c1787c942/african-lion-2888519_1280.jpg",
                "artist_id": 2115,
                "artist_name": "nehatest",
                "followed_by_current_user": false,
                "follow_status": false,
                "user_type": "Artist",
                "song_count": 0,
                "following_count": 16,
                "followers_count": 11,
                "comments_count": 0,
                "arrow": false
            }
        },
        {
            "id": "1115",
            "type": "playlist",
            "attributes": {
                "id": 1115,
                "status": "public_playlist",
                "play_list_type": "Audio",
                "repost_count": 1,
                "play_count": 23,
                "created_at": "2023-07-26T05:57:59.070Z",
                "updated_at": "2023-07-28T07:38:54.628Z",
                "name": "Created by neha",
                "playlist_items": {
                    "data": [
                        {
                            "id": "2202",
                            "type": "track_file",
                            "attributes": {
                                "title": "INVITATION",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcG9kIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ee10d574b42bd5cbe4502f42694edaf04f4d83f3/tomp3.cc%20-%20Selena%20Gomez%20%20Come%20%20Get%20It.mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcHNkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--5ceb01eed5905e6f71fbcaf5e83487d921c96bd3/download%20(11).jfif",
                                "artist_name": "demo user",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcnNlIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00e54b153ce1ac03fbbbcb750769110cacf5c4d2/image",
                                "play_count": 324,
                                "comments_count": 3,
                                "repost_count": 1
                            }
                        },
                        {
                            "id": "2245",
                            "type": "track_file",
                            "attributes": {
                                "title": "Lag Ja Gale",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbGtlIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--510df3cc563bf2869a7821cf8df04345530b0550/tomp3.cc%20-%20Shreya%20Ghoshal%20sings%20Lag%20Ja%20Gale%20Once%20More%20with%20Symphony%20Orchestra%20of%20Hemantkumar%20Musical%20Group.mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbG9lIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bba6dc25840f73ce6f39bb9657d60cedeab4ed94/ab67616d0000b2732e85cfcbec4eba1ff249b39a.jfif",
                                "artist_name": "nehatest",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1da800fb24a6a1d0888e5515dc64725c1787c942/african-lion-2888519_1280.jpg",
                                "play_count": 355,
                                "comments_count": 17,
                                "repost_count": 0
                            }
                        }
                    ]
                },
                "cover_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcTRoIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d16eabb843d2a9eabe2a7ff62c6f526e958cc1ac/image",
                "avatar": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1da800fb24a6a1d0888e5515dc64725c1787c942/african-lion-2888519_1280.jpg",
                "artist_id": 2115,
                "artist_name": "nehatest",
                "followed_by_current_user": false,
                "follow_status": false,
                "user_type": "Artist",
                "song_count": 2,
                "following_count": 16,
                "followers_count": 11,
                "comments_count": 20,
                "arrow": false
            }
        },
        {
            "id": "1114",
            "type": "playlist",
            "attributes": {
                "id": 1114,
                "status": "public_playlist",
                "play_list_type": "Audio",
                "repost_count": 70,
                "play_count": 110,
                "created_at": "2023-07-26T05:47:21.297Z",
                "updated_at": "2023-08-31T10:04:00.845Z",
                "name": "Neha create playlist",
                "playlist_items": {
                    "data": [
                        {
                            "id": "2192",
                            "type": "track_file",
                            "attributes": {
                                "title": "nxzsound",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmNkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bd6c0d4d146845cac7f7818db3c93d979536fb8c/wuxd0toau3e1rhoat4ac5httpw90%20(2).mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmdkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--09d565485ba915bc8e10b18f91f2639be0ba8b35/download%20(2).jfif",
                                "artist_name": "finalplan",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdFFiIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2320ab68949fa949f4f60e4bb4800b488acdfb37/0acf6786ffde317cd6d2d6e386114420.jpg",
                                "play_count": 984,
                                "comments_count": 91,
                                "repost_count": 70
                            }
                        },
                        {
                            "id": "2245",
                            "type": "track_file",
                            "attributes": {
                                "title": "Lag Ja Gale",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbGtlIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--510df3cc563bf2869a7821cf8df04345530b0550/tomp3.cc%20-%20Shreya%20Ghoshal%20sings%20Lag%20Ja%20Gale%20Once%20More%20with%20Symphony%20Orchestra%20of%20Hemantkumar%20Musical%20Group.mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbG9lIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bba6dc25840f73ce6f39bb9657d60cedeab4ed94/ab67616d0000b2732e85cfcbec4eba1ff249b39a.jfif",
                                "artist_name": "nehatest",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1da800fb24a6a1d0888e5515dc64725c1787c942/african-lion-2888519_1280.jpg",
                                "play_count": 355,
                                "comments_count": 17,
                                "repost_count": 0
                            }
                        }
                    ]
                },
                "cover_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcTBoIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--350608b305146cc0516683bcf625001427e95441/image",
                "avatar": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1da800fb24a6a1d0888e5515dc64725c1787c942/african-lion-2888519_1280.jpg",
                "artist_id": 2115,
                "artist_name": "nehatest",
                "followed_by_current_user": false,
                "follow_status": false,
                "user_type": "Artist",
                "song_count": 2,
                "following_count": 16,
                "followers_count": 11,
                "comments_count": 108,
                "arrow": false
            }
        },
        {
            "id": "1111",
            "type": "playlist",
            "attributes": {
                "id": 1111,
                "status": "public_playlist",
                "play_list_type": "Audio",
                "repost_count": 101,
                "play_count": 716,
                "created_at": "2023-07-20T14:09:10.474Z",
                "updated_at": "2023-08-31T10:04:00.809Z",
                "name": "test",
                "playlist_items": {
                    "data": [
                        {
                            "id": "2201",
                            "type": "track_file",
                            "attributes": {
                                "title": "Kooch Na Karin",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcGdkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--7ca4480e3aed2ddfaaebc6c14306ffd91b1f10b2/tomp3.cc%20-%20Selena%20Gomez%20%20Come%20%20Get%20It.mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcGtkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f14fe9234074abdac2aba24b42a805372fd24259/download%20(6).jfif",
                                "artist_name": "demo user",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcnNlIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00e54b153ce1ac03fbbbcb750769110cacf5c4d2/image",
                                "play_count": 4500,
                                "comments_count": 37,
                                "repost_count": 18
                            }
                        },
                        {
                            "id": "2192",
                            "type": "track_file",
                            "attributes": {
                                "title": "nxzsound",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmNkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bd6c0d4d146845cac7f7818db3c93d979536fb8c/wuxd0toau3e1rhoat4ac5httpw90%20(2).mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmdkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--09d565485ba915bc8e10b18f91f2639be0ba8b35/download%20(2).jfif",
                                "artist_name": "finalplan",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdFFiIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2320ab68949fa949f4f60e4bb4800b488acdfb37/0acf6786ffde317cd6d2d6e386114420.jpg",
                                "play_count": 984,
                                "comments_count": 91,
                                "repost_count": 70
                            }
                        },
                        {
                            "id": "2234",
                            "type": "track_file",
                            "attributes": {
                                "title": "barsaat",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa1FlIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e80d114d04609bdb4c69c81134840e2ce23379a4/tomp3.cc%20-%20Barsaat%20Ki%20Dhun%20Song%20%20Rochak%20K%20Ft%20Jubin%20N%20%20Gurmeet%20C%20Karishma%20S%20Rashmi%20V%20%20Ashish%20P%20%20Bhushan%20K.mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa1VlIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--76366d0f74032f83791d7f03f3df946f8c20133b/download.jfif",
                                "artist_name": "nehatest",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1da800fb24a6a1d0888e5515dc64725c1787c942/african-lion-2888519_1280.jpg",
                                "play_count": 1433,
                                "comments_count": 60,
                                "repost_count": 13
                            }
                        }
                    ]
                },
                "cover_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbXdoIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--91f584ca361438323b12cf1b65382857f89da047/image",
                "avatar": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1da800fb24a6a1d0888e5515dc64725c1787c942/african-lion-2888519_1280.jpg",
                "artist_id": 2115,
                "artist_name": "nehatest",
                "followed_by_current_user": false,
                "follow_status": false,
                "user_type": "Artist",
                "song_count": 3,
                "following_count": 16,
                "followers_count": 11,
                "comments_count": 188,
                "arrow": false
            }
        },
        {
            "id": "1102",
            "type": "playlist",
            "attributes": {
                "id": 1102,
                "status": "private_playlist",
                "play_list_type": "audio",
                "repost_count": 71,
                "play_count": 122,
                "created_at": "2023-07-18T19:16:03.226Z",
                "updated_at": "2023-08-31T10:04:00.687Z",
                "name": "nehatest",
                "playlist_items": {
                    "data": [
                        {
                            "id": "2192",
                            "type": "track_file",
                            "attributes": {
                                "title": "nxzsound",
                                "user_type": "creative",
                                "track_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmNkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bd6c0d4d146845cac7f7818db3c93d979536fb8c/wuxd0toau3e1rhoat4ac5httpw90%20(2).mp3",
                                "video_file": null,
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmdkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--09d565485ba915bc8e10b18f91f2639be0ba8b35/download%20(2).jfif",
                                "artist_name": "finalplan",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdFFiIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2320ab68949fa949f4f60e4bb4800b488acdfb37/0acf6786ffde317cd6d2d6e386114420.jpg",
                                "play_count": 984,
                                "comments_count": 91,
                                "repost_count": 70
                            }
                        },
                        {
                            "id": "2218",
                            "type": "track_file",
                            "attributes": {
                                "title": "The Heart Wants",
                                "user_type": "original",
                                "track_file": null,
                                "video_file": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdmtkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2f5818d8b100b2e51801f937fba993e59f88f602/tomp3.cc%20-%20Justin%20Bieber%20%20One%20Time%20Official%20Music%20Video_v720P.mp4",
                                "art_work": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdmdkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ade8f2a5208044c206f0fc591461fffdd63a1b34/images%20(2).jfif",
                                "artist_name": "original",
                                "artist_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcTRkIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3dbbb8be2670953e3df8e44516ade6583252842e/1674641373195.jfif",
                                "play_count": 0,
                                "comments_count": 3,
                                "repost_count": 1
                            }
                        }
                    ]
                },
                "cover_image": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbGtoIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--96bde684cbd79f2675994f00c2b21abf5883aadd/Screenshot%202023-06-05%20at%209.30.58%20PM.png",
                "avatar": "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lpIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1da800fb24a6a1d0888e5515dc64725c1787c942/african-lion-2888519_1280.jpg",
                "artist_id": 2115,
                "artist_name": "nehatest",
                "followed_by_current_user": false,
                "follow_status": false,
                "user_type": "Artist",
                "song_count": 2,
                "following_count": 16,
                "followers_count": 11,
                "comments_count": 94,
                "arrow": false
            }
        }
    ]

  const { handleAudioVideo, setHandleAudioVideo, handlePlayList } = useContext(AudioContextApi);
 
  const topPlayMusic = (item: any, index: any, songsList: any, trackName: any) => {
    if (trackName == "Playlists") {
      handlePlayLists(item)
    } else {
      if (handleAudioVideo === 1 || handleAudioVideo === 0) {
       
        setHandleAudioVideo(2)
        handlePlayList(item, index, songsList, trackName)
      } else {
        setHandleAudioVideo(1)
      }
    }
  }
  
  const setCover = (item: any) => {
    if(item?.attributes?.art_work){
      return item?.attributes?.art_work
    }else{
      return playlistProfile
    } 
  }
 
  return (
    <Box
      style={{
        borderRadius: "20px",
        marginBottom: "13px",
        width: "100%",
        // marginTop: "22px",
        maxHeight: "415px",
        display: "flex",
        flexDirection: "column"
      }}>
      <Grid style={{ padding: "6px 0px 4px 0px", marginTop: "1%" }} container>
        <Grid item xs={7} md={7} lg={8}>
          <Row className=" width_100">
            <Typography className="top-head">{songsList && 'Top ' + '100'}</Typography>
          </Row>
        </Grid>
        <Grid item xs={4} sm={5}
        md={5}
        lg={4}
          style={{ display: "flex" }} className="second-head-percentage" >
          <Hidden xsDown>
            <Grid item xs={3}>
              <p className=" text_white1 top_size_16 hiddnDaysScore " style={{ marginTop: "4px",paddingLeft:"10px" }}>24hr%</p>
            </Grid>
            <Grid item xs={4}>
              <p className="text_white1 top_size_16" style={{ marginTop: "4px", textAlign: "start",paddingLeft:"10px" }}>7d%</p>
            </Grid>
            <Grid item xs={5} style={{ textAlign: "center" }} className="hiddnDaysGraph">
              <p className="text_white1 top_size_16 hiddnDaysGraph" style={{ marginTop: "4px" }}>
                Last 7d
              </p>
            </Grid>


          </Hidden>
        </Grid>
      </Grid>


      <Box
        className={beatList > 0 ? "scroll-noslider mostly-customized-scrollbar" : "label-scroll mostly-customized-scrollbar"}
      >
        {songsList.length>0&&songsList.map((item: any, index: any) => {
          return (
            <Col span={24} onClick={() => topPlayMusic(item, item.id, songsList, trackName)} className="musicRow">
              <Grid container style={{ padding: "6px 0px 4px 0px" }} className="main-container-space">
                <Grid item md={4} lg={3} xs={3} sm={4}
                  className="profileDetailBlock">


                  <div className=" width_100 " style={{ display: "flex" }}>
                    <div>
                      <Row className="sn-grid mr_1 ml_05">
                        {index < 9 ? "0" + Number(index + 1) : index + 1}
                      </Row>


                      <img src={item.attributes.arrow ? iconUp : down} className="icon-up mr_05 ml_05" onClick={item.handlePlayList} />
                    </div>
                    <div>
                      <img style={{ borderRadius: "8px", border: "solid 1px gray", width: isMusicButton ? '80px' : '48px' }}
                        className="song-cover mr_1 topsongs-cover-listing"
                        src={trackName === "Playlists" ?
                          item?.attributes?.cover_image : setCover(item)} alt=""
                      />
                    </div>
                    <div >
                      <Row>
                        <Row
                          justify="start" className="width_100 name_hiddne text_white cursor_pointer "> {item?.attributes?.name || 'Default Song(Unknown)'}</Row>
                        <Row
                          justify="start" className="width_100 text_gray cursor_pointer artist-name-width">{item?.attributes?.playlist_items?.data.length > 0 ? item?.attributes?.playlist_items?.data.length + ' Tracks' : '0 Tracks'}</Row>
                      </Row>
                    </div>
                  </div>
                </Grid>
                <Col xs={6} className="HiddnFullScreen hours-list"
                // onClick={item.handlePlayList} 
                >
                  <Row justify="end" className="iconDelete">
                    <img src={expandMore} className=" ml-1 mr_1" height="28px" width="28px" />
                  </Row>
                </Col>
                <Hidden smDown >
                <Grid item
                  // md={3}
                  md={1}
                  xs={2} sm={4} lg={3}
                  className="playButtonContain" style={{ display: "flex", alignItems: "center" }}>
                  <Row className=" width_100" >
                    <Hidden xsDown>
                      <Col span={24} >

                    {
                        !isMusicButton &&
                        <Hidden mdDown>
                        <img src={player_Banner} style={{width: '208px', height: '34px'}}/>
                        </Hidden>
                    }
                       
                        {/* <img
                          src={playlistData?.id === item.id && handleAudioVideo === 2 ? pauseA : playButton}
                          height={selectedIndex === item.id && handleAudioVideo === 2 ? "16px" : "24px"}
                          width={selectedIndex === item.id && handleAudioVideo === 2 ? "17px" : "24px"}
                          className="play_whites mr_1 hiddnGraph"
                        /> */}
                        {/* <Hidden mdDown>
                          {trackName !== "Playlists" && item?.attributes?.track_file &&
                            <Waveform url={item?.attributes?.track_file} id={item?.id} height={24} />
                          }
                        </Hidden> */}
                      </Col>
                    </Hidden>


                  </Row>
                </Grid>
                </Hidden>
                <Grid item sm={3} md={2} lg={2}
                  
                  className="iconButton play-repost-comment-container">


                  <div className=" display-play_dark_back" >
                    <Col className="colButton width_100">
                      <div style={{ display: "flex", alignItems: "center" }} className='iconContent'>
                        <img className="iconImg"
                          src={playcountP}
                          height="13px"
                          width="11.5px"
                        />
                        <Box className="play-times iconContent">
                          {item?.attributes?.play_count ? millify(item?.attributes?.play_count) : "0"}
                        </Box></div>
                    </Col>
                    <Col className="colButton width_100">
                      <div style={{ display: "flex" }} className='iconContent'>
                        <img className="iconImg" src={loopIc} height="19px" width="19px" />
                        <Box className="play-times iconContent">
                          {item?.attributes?.repost_count ? millify(item?.attributes?.repost_count) : "0"}
                        </Box>
                      </div> </Col>
                    <Col className="colButton width_100">
                      <div style={{ display: "flex", alignItems: "flex-end" }} className='iconContent'>
                        <img className="iconImg" src={chatPlaylist} height="20px" width="20px" />
                        <Box className="play-times iconContent">
                          {item?.attributes?.comments_count ? millify(item?.attributes?.comments_count) : "0"} </Box>
                      </div>
                    </Col>
                  </div>
                </Grid>
                <Grid item sm={5} md={5} lg={4}
                  className="hidden_percentage"
                // onClick={item.handlePlayList}
                >
                  {/* <Hidden xsDown> */}
                  <LineChart
                    //    seven_days={item?.attributes?.last_seven_days_percentage}
                    //    twenty_four_days={item?.attributes?.last_twenty_four_hours_percentage}
                    //    seven_days_graph={item?.attributes?.last_seven_days_graph}
                       fullscreen={expandMore}
                    //    onFullScreen={(e: any) => onFullScreen(e, item)}
                     />
                  {/* </Hidden> */}
                </Grid>
              </Grid>
            </Col>
          );


        })}
      </Box>
    </Box>
  );
}
export default LabelTopSongs;
// Customizable Area End