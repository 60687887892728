// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";




export interface Props {
  navigation: any;
  id: string;
}

interface S {
  
  index: any
}

interface SS {
  id: any;
}

export default class DashboardAnalyticscontroller extends BlockComponent<
  Props,
  S,
  SS
> {

  
  subscriptionPlan = localStorage.getItem("subscription_plans")

  


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
      index:"states"
    };



    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }
  changetabs=(index:string)=>{
    this.setState({index:index})
  }

 

}
// Customizable Area End