// Customizable Area Start
import { Button,  Typography, Box, Slider } from "@material-ui/core";
import React from "react";
import './DashboardAnalytics.css'
import { plays, album1, reshared, commentBlue } from "blocks/dashboard/src/assets";
import DashboardAnalyticscontroller from "./DashboardAnalyticsController.web";


export default class DashboardAnalytics extends DashboardAnalyticscontroller {
    constructor(props: any) {
        super(props);

    }

   
 

    render() {

        const statesAnalytics = () => {
            return (<div >
                <Box className="analytics_topheader_container">

                    <Box>
                        <Typography className="Top-141">Top 100 States</Typography>
                    </Box>
                    <Box className="analytics_topheader_element">
                    <Box>
                            <Button style={{ color: "white", borderRadius: "35px", background: this.state.index == "states" ? "blue" : "" }} onClick={()=>this.changetabs("states")} data-test-id="states">States</Button>
                        </Box>
                        <Box>
                            <Button style={{background: this.state.index == "cities" ? "blue" : "" , color: "white", borderRadius: "35px", }} onClick={()=>this.changetabs("cities")} data-test-id="cities">Cities</Button>
                        </Box>
                        <Box>
                            <Button style={{ borderRadius: "35px",color: "white",  background: this.state.index == "listeners" ? "blue" : "" }} onClick={()=>this.changetabs("listeners")} data-test-id="listeners">Listeners</Button>
                        </Box>
                    </Box>
                </Box>

                <Box className="topAnaliticbox">
                    {Array.from({ length: 20 }).map((item, index) => <Box className="topstatscontainer">
                        <Box className="topstats_container_element">
                            <Typography>{index + 1}</Typography>
                            <Typography>California</Typography>
                        </Box>

                        <Box style={{
                            display: "flex",

                        }}  className="analytics_slider">
                            <Slider defaultValue={70} aria-label="Default" valueLabelDisplay="auto" />
                        </Box>
                        <Box  className="allplays_stats">
                            <Box style={{ display: "flex", }}>
                                <img
                                    className="iconImg"
                                    src={plays}
                                    height="24px"
                                    width="24px"
                                />
                                <Typography>6.57M</Typography>
                            </Box>
                            <Box style={{ display: "flex", }}>
                                <img
                                    className="iconImg"
                                    src={reshared}
                                    height="24px"
                                    width="24px"
                                />
                                <Typography>6.57M</Typography>
                            </Box>
                            <Box style={{ display: "flex", }}>
                                <img
                                    className="iconImg"
                                    src={commentBlue}
                                    height="24px"
                                    width="24px"
                                />
                                <Typography>6.57M</Typography>
                            </Box>

                        </Box>

                    </Box>)}
                </Box>


            </div >
            )
        }

        const citiesAnalytics = () => {
            return (<div >
                <Box className="analytics_topheader_container">
                    <Box>
                        <Typography className="Top-141">Top 100 Cities</Typography>
                    </Box>
                    <Box

                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            borderRadius: "20px",
                            backgroundColor: "#212121",
                            padding: "6px",
                            
                        }}>
                        <Box>


                            <Button style={{borderRadius: "35px",  background: this.state.index == "states" ? "blue" : "" ,color: "white",}} onClick={()=>this.changetabs("states")} data-test-id="states">States</Button>
                        </Box>
                        <Box>
                            <Button style={{ color: "white", borderRadius: "35px", background: this.state.index == "cities" ? "blue" : "" }} onClick={()=>this.changetabs("cities")} data-test-id="cities">Cities</Button>
                        </Box>
                        <Box>
                            <Button style={{background: this.state.index == 'listeners' ? "blue" : "" , color: "white", borderRadius: "35px", }} onClick={()=>this.changetabs("listeners")} data-test-id="listeners">Listeners</Button>
                        </Box>
                    </Box>
                </Box>

                <Box className="topAnaliticbox">


                    {Array.from({ length: 30 }).map((item, index) => <Box className="topstatscontainer">
                        <Box className="topstats_container_element">
                            <Typography>{index + 1}</Typography>
                            <Typography>America</Typography>
                        </Box>

                        <Box style={{
                            display: "flex",

                        }} className="analytics_slider">
                            <Slider defaultValue={70} aria-label="Default" valueLabelDisplay="auto" />
                        </Box>
                        <Box className="allplays_stats">
                            <Box style={{ display: "flex", }}>
                                <img
                                    className="iconImg"
                                    src={plays}
                                    height="24px"
                                    width="25px"
                                />
                                <Typography>6.97M</Typography>
                            </Box>
                            <Box style={{ display: "flex", }}>
                                <img
                                    className="iconImg"
                                    src={reshared}
                                  
                                    width="24px"
                                    height="24.5px"
                                />
                                <Typography>6.570M</Typography>
                            </Box>
                            <Box style={{ display: "flex", }}>
                                <img
                                    className="iconImg"
                                    src={commentBlue}
                                    height="24px"
                                    width="24.5px"
                                />
                                <Typography>6.58M</Typography>
                            </Box>


                        </Box>

                    </Box>)}
                </Box>


            </div >
            )
        }

        const listenersAnalytics = () => {
            return (
            
            <div >


                <Box className="analytics_topheader_container">
                    <Box>
                    <span className="Top-141">
                   Top 1K Listeners
                   </span>
                    </Box>
                    <Box

                        style={{
                          
                            display: "flex",
                            borderRadius: "20px",
                            backgroundColor: "#212121",
                            justifyContent: "space-evenly",
                            padding: "6px"
                        }}>
                        <Box>
                            <Button style={{  background: this.state.index == "states" ? "blue" : "" }} onClick={()=>this.changetabs("states")} data-test-id="states" className="stats_tab">States</Button>
                        </Box>
                        <Box>
                            <Button className="stats_tab" style={{  background: this.state.index == "cities" ? "blue" : "" }} onClick={()=>this.changetabs("cities")} data-test-id="cities">Cities</Button>
                        </Box>
                        <Box>
                            <Button style={{  background: this.state.index == "listeners" ? "blue" : "" ,}} className="stats_tab" onClick={()=>this.changetabs("listeners")} data-test-id="listeners">Listeners</Button>
                        </Box>
                    </Box>
                </Box>

                <Box className="topAnaliticbox">
                    {Array.from({ length: 21 }).map((item, index) => <Box className="topstatscontainer">
                        <Box className="topstats_container_element">
                            <Typography>{index + 1}</Typography>
                            <img src={album1} className="iconImg" style={{ borderRadius: '60px' }}
                                height="40px"
                                width="40px"
                            />

                            <Typography style={{ whiteSpace: "nowrap" }}>Jason durela</Typography>
                        </Box>

                        <Box style={{
                            display: "flex",

                        }} className="analytics_slider">

                            <Slider defaultValue={60} aria-label="Default" valueLabelDisplay="auto" />
                        </Box>
                        <Box className="allplays_stats">
                            <Box style={{ display: "flex", }}>
                                <img
                                    className="iconImg alpha"
                                    src={plays}
                                    height="24px"
                                    width="24px"
                                />

                                <Typography>6.59M</Typography>
                            </Box>
                            <Box style={{ display: "flex", }}>
                                <img
                                    className="iconImg alp"
                                    src={reshared}
                                    height="24px"
                                    width="24px"
                                />
                                <Typography>6.57M</Typography>
                            </Box>
                            <Box style={{ display: "flex", }}>
                                <img
                                    className="iconImg"
                                    src={commentBlue}
                                    height="24px"
                                    width="24px"
                                />
                                <Typography>6.567M</Typography>
                            </Box>


                        </Box>

                    </Box>)}
                </Box>


            </div >
            )
        }

        const compRender = () => {
            switch (this.state.index) {
                case "states":
                    return statesAnalytics()
                case "cities":
                    return citiesAnalytics()
                default:
                    return listenersAnalytics()
            }
        }
        return (
            //Merge Engine DefaultContainer
            compRender()

            //Merge Engine End DefaultContainer
        );
    }
}
// Customizable Area End

