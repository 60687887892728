// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Box, Typography } from '@material-ui/core';
import axios from "axios";
import {  defaultProfile ,playButton} from "../../dashboard/src/assets";

import { useHistory } from "react-router-dom";

export const configJSON = require("./config.js");


interface IProfileMsgCard {
    isRecived: boolean,
    avtar: string,
    index: number,
    senderName: string,
    timetoken: string,
    text: string,
    userId: string,
    particularUserDataRes: any;
    isChartMessage?:any;
    onRedirect: (currentuserType: any, currentUserId: any) => void;
}

const ProfileTrackImg = ({ image }: { image: string }) => {
    return (
        <Box style={{ justifyContent: "center", alignItems: "center", display: "flex", position: "relative" }}>
            <img
                style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: 8,
                    padding: "0px 2px"

                }}
                src={image}
            />
            <img
                style={{
                    height: 24,
                    width: 24,
                    position: "absolute"
                }}
                src={playButton}
            />
        </Box>
    )
}

function ProfileMsgCard(props: IProfileMsgCard) {
    const { isRecived, avtar, userId, onRedirect, particularUserDataRes,isChartMessage } = props
    const [userData, setUserData] = useState<any>("")
    const [imageList, setImageList] = useState<any[]>([])
    const history = useHistory();
    const user_type: any = localStorage.getItem("user_type")
    const user_id: any = localStorage.getItem("loggedIn_userId")

    useEffect(() => {
        _getUserData()
        return () => { }
    }, [])
    const token: any = localStorage.getItem("token")

    const _getUserData = async () => {
        axios.get(
            `${configJSON.baseUrl}/bx_block_profile/user_profile?id=` + userId,
            {
                headers: {
                    "Content-Type": "application/json",
                    token: token
                },
            }
        )
            .then((responseJson) => {
                if (responseJson.data) {
                    setUserData(responseJson.data.data.attributes)
                }
            });

        axios.get(
            `${configJSON.baseUrl}/bx_block_messages/messages/share_user_profile?sender_id=` + userId,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: token
                },
            }
        )
            .then((responseJson) => {
                if (responseJson?.data) {
                    setImageList(responseJson.data.data)
                }
            });
    };

    const redirectProfile = (userType: any) => {
        if (userType == "creative") {
            history.push(`/creatives/beats?creative_id=${userId}`)
        } else if (userType == "listener") {
            history.push(`/listeners/activity?listener_id=${userId}`)
        } else {
            history.push(`/originals/beats?originals_id=${userId}`)
        }

    }

    let currentuserType = isRecived ? particularUserDataRes?.attributes?.artist_role : user_type
    let currentUserId = isRecived ? particularUserDataRes?.id : user_id


    return (
        <div style={{ height: isChartMessage?"none":"100%", width: "100%", padding: "5px 0px 20px 30px", display: "flex", alignItems: isRecived ? "flex-start" : "flex-end" }}>
            <Box style={{
                height: "100%", width: "100%", borderRadius: 15, backgroundColor: "#141414", position: "relative",
                padding: "10px"
            }}>
                <img
                    style={{
                        height: 60,
                        width: 60,
                        borderRadius: 60,
                        borderWidth: 5,
                        borderColor: "#141414",
                        position: "absolute",
                        marginTop: 100,
                        marginLeft: -30,
                        zIndex: 99,
                        backgroundColor: "#141414",
                        padding: 10

                    }}
                    onClick={() => onRedirect(currentuserType, currentUserId)}
                    src={avtar ? avtar : defaultProfile}
                />

                <Box style={{ marginTop: 10, marginLeft: 10, flexDirection: "row", alignItems: "center", display: "flex" }}>
                    <img
                        style={{
                            height: 29,
                            width: 29,
                            borderRadius: 29,

                        }}
                        onClick={() => onRedirect(userData?.attributes?.user_type, userData?.id)}
                        src={userData?.avatar}
                    />
                    <Box style={{ marginLeft: 10 }}>
                        <Typography style={{ color: "#fff", fontSize: 12, fontWeight: "bold" }}>{userData?.user_name}</Typography>
                        <Typography style={{ marginTop: 2, color: "rgba(143,146,161,0.5)", fontSize: 12, fontWeight: "bold" }}>{userData?.creative_type_name}</Typography>
                    </Box>
                </Box>
                <Box style={{ margin: 10 }}>
                    {imageList?.length > 0 ?
                        <Box>
                            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <ProfileTrackImg image={imageList[0]?.attributes?.art_work} />
                                <ProfileTrackImg image={imageList[1]?.attributes?.art_work} />
                                <ProfileTrackImg image={imageList[2]?.attributes?.art_work} />
                            </Box>
                            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 3 }}>
                                <ProfileTrackImg image={imageList[3]?.attributes?.art_work} />
                                <ProfileTrackImg image={imageList[4]?.attributes?.art_work} />
                                <ProfileTrackImg image={imageList[5]?.attributes?.art_work} />
                            </Box>
                        </Box>
                        :
                        <Box style={{ display: "flex", height: 160, justifyContent: "center", alignItems: "center" }}>
                            <Typography style={{ color: "#fff" }}>No Image Found! </Typography>
                        </Box>
                    }
                </Box>

                <div
                    onClick={() => {
                        redirectProfile(userData.user_type)
                    }}

                    style={{ height: 33, backgroundColor: "#000", borderRadius: 33, justifyContent: "center", alignItems: "center", display: "flex" }}>
                    <Typography style={{ color: "#fff", fontWeight: "bold",cursor:"pointer" }}>View Profile</Typography>
                </div>
            </Box>

        </div>
    )
}


export default ProfileMsgCard;
// Customizable Area End