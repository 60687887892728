// Customizable Area Start
import React from "react";
import { Line } from "react-chartjs-2";
import { minus, plus } from "blocks/dashboard/src/assets";
import { Grid } from "@material-ui/core";


const PlaylistLineChart = ({ seven_days, twenty_four_days, seven_days_graph, fullscreen, onFullScreen }: any) => {
    const getPlusMinus = (data: any): string => {
        if (data) {
            if (data.toString().startsWith("-")) {
                return `${minus}`
            } else if (data.toString().startsWith("0.00")) {
                return ""
            } else {
                return `${plus}`
            }
        } else {
            return ""
        }
    }

    const getPctTxt = (data: any): string => {
        if (data) {
            if (data.startsWith("-")) {
                return data.replace("-", "")
            }
            return data
        } else {
            return ""
        }
    }
    return (
        <Grid container xs={12} style={{display:"flex",alignItems:"center"}}>
            <Grid item xs={3}>
                <p className="percentage-hide hiddnDaysScore hours-list  commentCount play-times" style={{ display:"flex",alignItems:"center"}}>
                    <img src={getPlusMinus(twenty_four_days)} style={{ width: "12px", height: "10px", marginRight: "2px", display: getPlusMinus(twenty_four_days) == "" ? "none" : "initial" }} />

                    {getPctTxt(twenty_four_days == 100 ? '100' : parseFloat(twenty_four_days || '0.00').toFixed(2))}%</p>
            </Grid>
            <Grid item xs={4}>

                <p className="percentage-hide hours-list play-times" >
                    <img src={getPlusMinus(seven_days)} style={{ width: "12px", height: "10px", marginRight: "2px", display: getPlusMinus(seven_days) == "" ? "none" : "initial" }} />

                    {getPctTxt(seven_days == 100 ? '100' : parseFloat(seven_days || '0.00').toFixed(2))}%</p>
            </Grid>
            <Grid container item xs={5} style={{ display: "flex",alignItems:"center" }}>
                <Grid item xs={8}>
                    <div 
                    className="playlist-canvas-width" style={{}}
                    >

                        <Line data={{

                            // labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                            labels: Object.keys(seven_days_graph || ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']),
                            datasets: [
                                {
                                    label: '# of votes',
                                    // data: [18, 12, 6, 9, 12, 3, 9],
                                    data: Object.values(seven_days_graph || [18, 12, 6, 9, 12, 3, 9]),
                                    borderColor: ["blue"],
                                    border: "1px solid blue",
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    pointRadius: 0,
                                    pointHoverRadius: 0,
                                    showTooltips: false
                                },
                            ],
                        }}


                            //height={50}
                            //width={77}
                            options={{
                                maintainAspectRatio: false,
                                responsive: true,
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                // beginAtZero: true,
                                                display: false,

                                            },
                                            grid: {
                                                drawBorder: false,
                                                display: false,
                                            },
                                        },
                                    ],
                                    xAxes: [
                                        {
                                            ticks: {
                                                // beginAtZero: true,
                                                display: false,

                                            },
                                            grid: {
                                                drawBorder: false,
                                                display: false,
                                            },
                                        },
                                    ],
                                },
                                legend: {
                                    display: false,
                                    labels: {
                                        fontSize: 25,
                                    },
                                },
                                tooltips: {
                                    callbacks: {
                                        label: function (tooltipItem: any) {
                                            return tooltipItem.yLabel;
                                        },
                                        enabled: true
                                    }
                                }
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={4} style={{display:"flex",justifyContent:"flex-end"}}>

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={fullscreen} onClick={onFullScreen}
                            className=" ml_05 mr_1 cursor_pointer"

                            height="28px" width="28px"
                            style={{ margin: "auto" }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PlaylistLineChart
// Customizable Area End