// Customizable Area Start
import React, { useState,useContext } from 'react';
import { Box, Hidden } from '@material-ui/core';
import { Col, Layout, Row, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import '../../../blocks/dashboard/src/Charts/index.css'
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import { AudioContextApi } from 'blocks/dashboard/src/AudioContext/AudioContext.web';

export const ChartTemplate = ({ children, active }: any) => {

  const [loading, setLoading] = useState(false);
  const { handlePlayList ,isPlaylist} = useContext(AudioContextApi);
  const token = localStorage.getItem('token')
  return (
    <>
      {/* <Box className='chartMain'> */}
      <Layout className='chartLayout'>
        {/* .......................SideBar...................................... */}
        <Hidden smDown>
          <AsideLeft />
        </Hidden>
        <Content className='chartContent'>
          {/* for content page */}
          {/* <ChartsNew /> */}
          <HomeHeader />
          {
            loading ? (
              <Row>
                <Col>
                  <Spin className="loadingItem" size="large" />
                </Col>
              </Row>) :
              <>
                <Box
                  className={ `${token ? 'chart-body' : 'chart-body-withoutlogin'}` }
                  style={{ height: isPlaylist && active !== 4 ? "78vh" : "92vh" ,overflowY:"scroll"}}
                // style={{ overflow: "auto" }}
                // style={{ marginBottom: playPlaylist ? "6%" : "" }}
                >
                  {children}
                </Box>
              </>}

        </Content>
      </Layout>
      {/* </Box> */}
    </>
  );
};
// Customizable Area End