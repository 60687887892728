// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
export const configJSON = require("./config.js");
import { IMessage, ISender } from "./CustomMessageInterface";
import { defaultProfile } from "blocks/dashboard/src/assets";
import { getChannelName, readMessage, sendMessage } from "./ChatUtils";
// @ts-ignore
import PubNub from '../../../web/src/index';

export interface Props {
  navigation: any;
  id: string;
  otherUserID: any;
  newUserAdded?: any;
  selectedTab?: any;
  selectedTrackIndex?: any;
  particularUserDataRes?: any;
  selectedTrackIndexId: any;
}
interface S {
  message: any;
  getUserDetails: any;
  avtarUrl: any;
  input: string;
  isLoading: boolean;
  apiToken: any;
  listenerValue: any;
}
interface SS {
  id: any;
}

export default class DirectMessageUserController extends BlockComponent<
  Props,
  S,
  SS
> {
  apigetMessageCallId: string = "";
  apigetUserDataCallId: string = "";

  currentUserId: number;
  userId: any;
  ChannelName: any;
  messagesEndRef = React.createRef<HTMLDivElement>();
  scrollableDiv= React.createRef<HTMLDivElement>();
  token: string | null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      message: [],
      getUserDetails: null,
      avtarUrl: "",
      input: "",
      isLoading: false,
      apiToken: localStorage.getItem("token"),
      listenerValue: "",
    };
 

    this.currentUserId = Number(localStorage.getItem("loggedIn_userId"));
    this.userId = this.props.otherUserID || this.props.selectedTrackIndex;
    this.ChannelName = getChannelName(String(this.currentUserId), this.props.otherUserID || this.props.selectedTrackIndex);
    this.messagesEndRef = React.createRef();
    this.scrollableDiv = React.createRef();
    this.token = localStorage.getItem("token");

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    this._getMessages();
    this._getUserData();
    this.getUserAvtar();
    readMessage(this.props.otherUserID || this.props.selectedTrackIndex);

    if (PubNub) {
      PubNub.setUUID(this.currentUserId + "");
      const listener = {
        message: (envelope: any) => {
          this.setState((prevState) => ({
            message: [
              ...prevState.message,
              {
                id: prevState.message.length + 1,
                isRecived: this.currentUserId != envelope.publisher,
                sender: envelope.message.content.sender as ISender,
                text: envelope.message.content.text + "",
                timetoken: envelope.timetoken,
              },
            ],
          }));
          this.setState({ listenerValue: listener });
          if (this.currentUserId != envelope.publisher) {
            readMessage(this.props.otherUserID || this.props.selectedTrackIndex);
          }
        },
      };

      PubNub.addListener(listener);
      PubNub.subscribe({ channels: [this.ChannelName] });
    }
  }

 
  componentDidUpdate(prevProps: any ) {
    if (
      this.props.otherUserID !== prevProps.otherUserID ||
      this.props.selectedTrackIndex !== prevProps.selectedTrackIndex ||
      this.props.selectedTab !== prevProps.selectedTab
    ) {
      this._getMessages();
    this._getUserData();
    this.getUserAvtar();
    readMessage(this.props.otherUserID || this.props.selectedTrackIndex);
      //new code 
      if (PubNub) {
        PubNub.setUUID(this.currentUserId + "");
        const listener = {
          message: (envelope: any) => {
            this.setState((prevState) => ({
              message: [
                ...prevState.message,
                {
                  id: prevState.message.length + 1,
                  isRecived: this.currentUserId != envelope.publisher,
                  sender: envelope.message.content.sender as ISender,
                  text: envelope.message.content.text + "",
                  timetoken: envelope.timetoken,
                },
              ],
            }));
            this.setState({ listenerValue: listener });
            if (this.currentUserId != envelope.publisher) {
              readMessage(this.props.otherUserID || this.props.selectedTrackIndex);
            }
          },
        };
  
        PubNub.addListener(listener);
        PubNub.subscribe({ channels: [this.ChannelName] });
      }
    }
  }

  async componentWillUnmount() {
    if (PubNub) {
      PubNub.removeListener(this.state.listenerValue);
      PubNub.unsubscribeAll();
    }
  }

  convertChatResponseToPubNub = (message: any[]): IMessage[] => {
    if (!Array.isArray(message)) {
      // Handle the case where message is not an array
      console.log('Invalid input: message is not an array');
      return [];
    }
    return message?.map((message) => {
      const {
        id,
        attributes: {
          profile_image: avtar,
          artist_name: name,
          message: text,
          // read_status: isRead,
          message_time: timestamp,
          sender_id: senderId,
          // receiver_id: receiverId,
          receiver,
        },
      } = message;

      const sender = {
        id: senderId,
        name,
        avtar,
      };

      return {
        id,
        isRecived: receiver,
        sender,
        text,
        timetoken: new Date(timestamp).getTime() * 10000,
      };
    }) as any;
    // }
  };

  _getMessages = () => {
    this.setState({ isLoading: true });
    const header = {
               "Content-Type": "application/json;charset=UTF-8",
      token: this.state.apiToken,
    };
let getUserId=this.props.otherUserID || this.props.selectedTrackIndex;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetMessageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_messages/messages/user_chat_screen?sender_id=` +
        getUserId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserAvtar = async () => {
    const profilePic: any = localStorage.getItem("profile_img");

    if (profilePic != "null") {
      this.setState({ avtarUrl: profilePic });
    } else {
      this.setState({ avtarUrl: defaultProfile });
    }
  };

  _getUserData = async () => {
    const header = {
               "Content-Type": "application/json;charset=UTF-8",
      token: this.state.apiToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let getUserId=this.props.otherUserID || this.props.selectedTrackIndex;

    this.apigetUserDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_profile/user_profile?id=` + getUserId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChange = (e:any) => {
    // Update the 'input' state when the input value changes
    this.setState({ input: e.target.value });
  };
handleGridOnClick=()=>{
  this.onSend(this.state.input)
   this.handleSearchInput()
}
  handleKeyPress = (event:any) => {
    // Check if the 'Enter' key is pressed
    if (event.key === 'Enter') {
      // Call the onSend function with the current input value
      this.onSend(this.state.input);

      // Clear the input field by updating the 'input' state to an empty string
      this.setState({ input: '' });
    }
  };

  onSend = async (msgTxt: string) => {
    if (msgTxt == "") {
      return;
    }
    let getUserId=this.props.otherUserID || this.props.selectedTrackIndex;

    const message = {
      content: {
        text: msgTxt.trim(),
        sender: {
          id: this.currentUserId,
          name: "",
          avtar: this.state.avtarUrl,
        },
      },
      type: "",
      id: crypto.getRandomValues(new Uint8Array(16)),
      // id: Math.random().toString(16).substr(2)
    };
    try {
      await sendMessage(getUserId, msgTxt);
      PubNub.publish({ channel: this.ChannelName, message }, function(
        status: any,
        response: any
      ) {});
      this.props.selectedTrackIndexId(getUserId);

      this.props.newUserAdded();

      if (this.state.message.length === 0) {
         this._getMessages();
      }
    } catch (error) {
      // Handle errors from sendMessage or _getMessages
      console.log(error);
    }
  };

  handleSearchInput = () => {
    if (this.scrollableDiv.current) {
      this.scrollableDiv.current.scrollTop = this
        .scrollableDiv.current.scrollHeight;
    }
    this.setState({ input: "" });
  };

  getMessageListApiSuccessCall = (responseJson: any) => {
    console.log(responseJson.data,"messagelist")
    this.setState({
      isLoading: false,
    });
    if (responseJson.data) {
      this.setState({
        message: this.convertChatResponseToPubNub(responseJson.data),
      });
    } else {
      this.setState({ message: [] });
    }
  };
  getUserDataApiSuccessCall = (responseJson: any) => {
    console.log(responseJson.data,"userList")
   
    if (responseJson.data) {
      this.setState({ getUserDetails: responseJson.data });
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apigetMessageCallId) {
        this.getMessageListApiSuccessCall(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.apigetUserDataCallId) {
        this.getUserDataApiSuccessCall(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }

      //this.setState({loading: false})
    }
  }
}
// Customizable Area End
