// Customizable Area Start
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Grid, Box, Button, Dialog } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { Row, Input, Typography } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import "../../../dashboard/assets/css/HomeHeader.css";
import "./FilterPopup.css";
import axios from "axios";

const urlConfig = require("./../../../../framework/src/config");
const baseUrl = urlConfig.baseURL;

export const CreativeTypeComponent = (props: any) => {
  const {
    selectCreative,
    selectPlayCount,
    selectRanking,
    CreativeData,
    selectedSingleCreativeType,
    creativeSelect,
    location,
    clearCreativeType,
  } = props;
  return (
    <>
      {!selectCreative && !selectPlayCount && !selectRanking && (
        <Grid className="hide_for_MVP_release">
          <Grid
            style={{ height: "50px", margin: "10px", borderRadius: "25px" }}
          >
            <Row
              // className="left_part"
              className="search_location"
            >
              <Input
                style={{
                  width: "100%",
                  backgroundColor: "rgb(29 28 28)",
                  border: " 2px solid rgb(29 28 28)",
                  borderRadius: "20px",
                }}
                onChange={props.haldleSearchInput}
                prefix={
                    // @ts-ignore 
                  <SearchOutlined
                    translate
                    className="text_white1"
                    style={{
                      backgroundColor: "rgb(29 28 28)",
                      fontSize: "18px",
                      padding: "2px",
                    }}
                  />
                }
                // placeholder={<p className="text_white"></p>}
                placeholder="Search location"
              />
            </Row>
          </Grid>
          <div className="main-scroll" style={{ height: "280px" }}>
            {location.length !== 0 &&
              location.map((loc: any) => {
                return (
                  <Grid
                    style={{
                      height: "30px",
                      margin: "10px",
                      borderRadius: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    <div
                      className="hoverloc"
                      style={{
                        textAlign: "left",
                        marginLeft: "10px",
                        color: "grey",
                      }}
                    >
                      {loc}
                    </div>
                  </Grid>
                );
              })}
          </div>
        </Grid>
      )}

      {!selectPlayCount && !selectRanking && (
        <Grid container style={{ justifyContent: "space-around" }}>
          <Grid
            item
            style={{
              color: "white",
              width: "100%",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            {" "}
            Select Creative Type{" "}
          </Grid>
          {CreativeData.length !== 0 &&
            CreativeData.map((creative: any) => {
              return (
                <Grid item style={{}}>
                  <Grid
                    className="button-upload-types"
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        selectedSingleCreativeType == creative
                          ? "blue"
                          : "rgb(48, 48, 48)",
                    }}
                    onClick={() => {
                      creativeSelect(creative);
                    }}
                  >
                    {creative}
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      )}

      {!selectPlayCount && !selectRanking && selectedSingleCreativeType != "" && (
        <Grid
          container
          style={{
            justifyContent: "space-around",
            backgroundColor: "#171717",
            marginTop: "20px",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <Grid
            item
            style={{
              color: "white",
              width: "100%",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            {" "}
            Selected Creative Type{" "}
            <CloseOutlined
              onClick={clearCreativeType}
              style={{
                backgroundColor: "red",
                borderRadius: "50%",
                marginLeft: "10px",
                height: "18px",
                width: "18px",
                cursor: "pointer",
              }}
            />{" "}
          </Grid>

          <Grid item style={{}}>
            <Grid
              className="button-upload-types"
              style={{ backgroundColor: "rgb(48, 48, 48)" }}
              //onClick = {() => {creativeSelect(creat)}}
            >
              {selectedSingleCreativeType}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const PlaycountComponent = (props: any) => {
  const {
    selectPlayCount,
    playCount,
    selectedSinglePlayCount,
    playcountSelect,
    clearPlayCount,
  } = props;
  return (
    <>
      {selectPlayCount && (
        <Grid container style={{ justifyContent: "space-around" }}>
          <Grid
            item
            style={{
              color: "white",
              width: "100%",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            {" "}
            Select Play Count{" "}
          </Grid>
          {playCount.length !== 0 &&
            playCount.map((play: any) => {
              return (
                <Grid item style={{}}>
                  <Grid
                    className="button-upload-types"
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        selectedSinglePlayCount == play
                          ? "blue"
                          : "rgb(48, 48, 48)",
                    }}
                    onClick={() => {
                      playcountSelect(play);
                    }}
                  >
                    {play}
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      )}

      {selectPlayCount && selectedSinglePlayCount != "" && (
        <Grid
          container
          style={{
            justifyContent: "space-around",
            backgroundColor: "#171717",
            marginTop: "20px",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <Grid
            item
            style={{
              color: "white",
              width: "100%",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            {" "}
            Selected Play Count{" "}
            <CloseOutlined
              onClick={clearPlayCount}
              style={{
                backgroundColor: "red",
                borderRadius: "50%",
                marginLeft: "10px",
                height: "18px",
                width: "18px",
                cursor: "pointer",
              }}
            />{" "}
          </Grid>

          <Grid item style={{}}>
            <Grid
              className="button-upload-types"
              style={{ backgroundColor: "rgb(48, 48, 48)" }}
              //onClick = {() => {creativeSelect(creat)}}
            >
              {selectedSinglePlayCount}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const RankingComponent = (props: any) => {
  const {
    selectRanking,
    ranking,
    selectedRanking,
    rankingSelect,
    clearRanking,
  } = props;
  return (
    <>
      {selectRanking && (
        <Grid container style={{ justifyContent: "space-around" }}>
          <Grid
            item
            style={{
              color: "white",
              width: "100%",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            {" "}
            Select Ranking{" "}
          </Grid>
          {ranking.length !== 0 &&
            ranking.map((rank: any) => {
              return (
                <Grid item style={{}}>
                  <Grid
                    className="button-upload-types"
                    style={{
                      cursor: "pointer",
                      backgroundColor: selectedRanking.includes(rank)
                        ? "blue"
                        : "rgb(48, 48, 48)",
                    }}
                    onClick={() => {
                      rankingSelect(rank);
                    }}
                  >
                    {rank}
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      )}

      {selectRanking && selectedRanking.length != 0 && (
        <Grid
          container
          style={{
            justifyContent: "space-around",
            backgroundColor: "#171717",
            marginTop: "20px",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <Grid
            item
            style={{
              color: "white",
              width: "100%",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            {" "}
            Selected Ranking{" "}
            <CloseOutlined
              onClick={clearRanking}
              style={{
                backgroundColor: "red",
                borderRadius: "50%",
                marginLeft: "10px",
                height: "18px",
                width: "18px",
                cursor: "pointer",
              }}
            />{" "}
          </Grid>

          {selectedRanking.length != 0 &&
            selectedRanking.map((creat: any) => {
              return (
                <Grid item style={{}}>
                  <Grid
                    className="button-upload-types"
                    style={{ backgroundColor: "rgb(48, 48, 48)" }}
                    //onClick = {() => {creativeSelect(creat)}}
                  >
                    {creat}
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      )}
    </>
  );
};

function FilterPopup(props: any) {
  const {
    openPopUp,
    handlePopUp,
    setPlaycountValues,
    setCreativeTypesValues,
  } = props;

  const [selectCreative, setSelectCreative] = useState<any>(false);
  const [selectPlayCount, setSelectPlayCount] = useState<any>(false);
  const [selectRanking, setSelectRanking] = useState<any>(false);

  const CreativeData = [
    "Artist",
    "Engineer",
    "Musician",
    "Producer",
    "Songwriter",
    "Videographer",
  ];
  let [selectedCreativeType] = useState<any>([]);
  let [selectedRanking, setSelectedRanking] = useState<any>([]);

  let [selectedSingleCreativeType, setSelectedSingleCreativeType] = useState<
    any
  >("");
  let [selectedSinglePlayCount, setSelectedSinglePlayCount] = useState<any>("");

  const location = [
    "Arkoeb",
    "Robjobdid",
    "Basmudaj",
    "Vomeheg",
    "Paipja",
    "Lefukabe",
    "Paitnu",
    "Arkoeb",
    "Robjobdid",
    "Basmudaj",
    "Vomeheg",
    "Paipja",
    "Lefukabe",
    "Paitnu",
  ];

  const playCount = [
    "Below 1B",
    "Below 100M",
    "Below 1M",
    "Below 100K",
    "Below 1K",
  ];

  const token: any = localStorage.getItem("token");

  const useStyles = makeStyles((theme) => {
    return {
      mainGrid: {
        display: "flex",
        flexDirection: "row",
        height: "370px",
        margin: "10px",
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          height: "100%",
          overflow: "auto",
        },
      },
  
      leftGrid: {
        width: "275px",
        margin: "10px",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          height: "500px",
        },
      },
      rightGrid: {
        width: "275px",
        margin: "10px",
        alignItems: "center",
        // overflowY: 'auto',
        [theme.breakpoints.down("xs")]: {
          height: "500px",
        },
      },
      locationHover: {
        backgroundColor: "rgb(40, 40, 40)",
        border: " 2px solid rgb(40, 40, 40)",
        borderRadius: "20px",
      },
    };
  });  

  const classes = useStyles();

  const filterValues = () => {
    setPlaycountValues(selectedSinglePlayCount);
    setCreativeTypesValues(selectedSingleCreativeType);
    handlePopUp();
  };

  const selectCreativeTypes = async () => {
    setSelectPlayCount(false);
    setSelectRanking(false);

    await axios
      .get(`${baseUrl}/account_block/accounts/creative_types`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token,
        },
      })
      .then((response: any) => {
        console.log(response?.data?.data?.creative_types, "creative types");
        //setCreativeData(response?.data?.creative_types)
      })
      .catch((err: any) => {
        console.log(err.response, "eeeeeeeeeeeeeeeee");
      });
    setSelectCreative(true);
  };

  const selectplaycountTypes = () => {
    setSelectCreative(false);
    setSelectPlayCount(true);
    setSelectRanking(false);
  };

  const creativeSelect = (creative: any) => {
    if (selectedSingleCreativeType == creative) {
      setSelectedSingleCreativeType("");
    } else {
      setSelectedSingleCreativeType(creative);
    }
  };

  const playcountSelect = (play: any) => {
    if (selectedSinglePlayCount == play) {
      setSelectedSinglePlayCount("");
    } else {
      setSelectedSinglePlayCount(play);
    }
  };

  const selectCityStateTypes = () => {
    setSelectCreative(false);
    setSelectPlayCount(false);
    setSelectRanking(false);
  };

  const selectRankingTypes = () => {
    setSelectRanking(true);
    setSelectCreative(false);
    setSelectPlayCount(false);
  };

  const clearCreativeType = () => {
    setSelectedSingleCreativeType("");
  };

  const clearPlayCount = () => {
    setSelectedSinglePlayCount("");
  };

  useEffect(() => {
    // console.log(selectedCreativeType, 'select use ef')
    // selectedCreativeType.map((cr :any) =>{
    //   console.log(cr, 'creat')
    // })
    // if(selectedCreativeType.length == 0){
    //   setSelectCreative(false)
    // }
  }, [selectedCreativeType]);
console.log(setSelectedRanking,"needs to remove from advancedSearch")
  return (
    <Box
      sx={{
        width: 400,
        height: 400,
      }}
    >
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={openPopUp}
        onClose={handlePopUp}
        BackdropProps={{
          style: {
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.15)",
          },
        }}
        PaperProps={{ className: "dialog-Paper" }}
        disableBackdropClick
      >
        <Grid className="send-popup" style={{ overflowX: "hidden" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "end",
              padding: "5px 10px 0px 0px",
            }}
          >
            <CloseOutlined
              onClick={handlePopUp}
              style={{ height: "20px", width: "20px", cursor: "pointer" }}
            />
          </div>
          <Typography
            style={{
              fontSize: "22px",
              padding: "12px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            Filter
          </Typography>
          <Grid className={`${classes && classes.mainGrid} height_mobile_filter`}>
            <Grid className={classes && classes.leftGrid}>
              <Grid style={{ display: "flex", flexDirection: "column" }}>
                <Grid
                test-id="selectPlaycount_filterPopup_test_id"
                  className="typehove"
                  style={{
                    cursor: "pointer",
                    height: "50px",
                    margin: "10px",
                    borderRadius: "25px",
                    backgroundColor:
                      selectedSinglePlayCount != "" || selectPlayCount
                        ? "rgb(69, 36, 146)"
                        : "rgb(40, 40, 40)",
                  }}
                  onClick={selectplaycountTypes}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      padding: "12px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    PLAY COUNT
                  </Typography>
                </Grid>
                <Grid
                test-id="selectCreativeType_filterPopup_test_id"
                  className="typehove"
                  style={{
                    cursor: "pointer",
                    height: "50px",
                    margin: "10px",
                    borderRadius: "25px",
                    backgroundColor:
                      selectedSingleCreativeType !== "" || selectCreative
                        ? "rgb(69, 36, 146)"
                        : "rgb(40, 40, 40)",
                  }}
                  onClick={selectCreativeTypes}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      padding: "12px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    CREATIVE TYPES
                  </Typography>
                </Grid>
                <Grid
                test-id="selectCityStateType_filterPopup_test_id"
                  className="hide_for_MVP_release"
                  style={{
                    cursor: "pointer",
                    height: "50px",
                    margin: "10px",
                    borderRadius: "25px",
                    backgroundColor: "rgb(40, 40, 40)",
                  }}
                  onClick={selectCityStateTypes}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      padding: "12px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    STATE
                  </Typography>
                </Grid>
                <Grid
                test-id="selectCityStateType_filterPopup_test_id_0"
                  className="hide_for_MVP_release"
                  style={{
                    cursor: "pointer",
                    height: "50px",
                    margin: "10px",
                    borderRadius: "25px",
                    backgroundColor: "rgb(40, 40, 40)",
                  }}
                  onClick={selectCityStateTypes}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      padding: "12px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    CITY
                  </Typography>
                </Grid>
                <Grid
                test-id="selectRankingType_filterPopup_test_id"
                  className="typehove hide_for_MVP_release"
                  style={{
                    cursor: "pointer",
                    height: "50px",
                    margin: "10px",
                    borderRadius: "25px",
                    backgroundColor:
                      selectedRanking.length !== 0 || selectRanking
                        ? "rgb(69, 36, 146)"
                        : "rgb(40, 40, 40)",
                  }}
                  onClick={selectRankingTypes}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      padding: "12px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    RANKING
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={`${classes && classes.rightGrid}`}>
              <CreativeTypeComponent
                selectCreative={selectCreative}
                selectPlayCount={selectPlayCount}
                selectRanking={selectRanking}
                CreativeData={CreativeData}
                selectedSingleCreativeType={selectedSingleCreativeType}
                creativeSelect={creativeSelect}
                location={location}
                clearCreativeType={clearCreativeType}
              />

              <PlaycountComponent
                selectPlayCount={selectPlayCount}
                selectRanking={selectRanking}
                selectedSingleCreativeType={selectedSingleCreativeType}
                playCount={playCount}
                selectedSinglePlayCount={selectedSinglePlayCount}
                playcountSelect={playcountSelect}
                clearPlayCount={clearPlayCount}
              />

              {/* <RankingComponent selectRanking = {selectRanking} ranking = {ranking} selectedRanking = {selectedRanking} rankingSelect = {rankingSelect} clearRanking = {clearRanking} /> */}
            </Grid>
          </Grid>
          <Button
          test-id="filterValues_filterPopup_test_id"
            style={{
              backgroundColor: "red",
              padding: "12px",
              color: "white",
              borderRadius: "25px",
              width: "230px",
              fontWeight: "bold",
            }}
            onClick={filterValues}
          >
            Apply
          </Button>
        </Grid>
      </Dialog>
    </Box>
  );
}

export default FilterPopup;
// Customizable Area End