// Customizable Area Start
import React, { useEffect, useState, useRef } from 'react'
import TextMsgCard from './TextMsgCard.web';
import TrackMsgCard from './TrackMsgCard.web';
import ProfileMsgCard from './ProfileMsgCard.web';
export const configJSON = require("../../../framework/src/config.js");
import { useHistory } from "react-router-dom";
import { defaultProfile } from '../../dashboard/src/assets';


export type MessageTypes = "profile" | "track" | "video" | "txt"
export default function MessageCard({ chatData,particularUserDataRes,isChartMessage }: any) {
    const history = useHistory();
    const [messageType, setMessageType] = useState<{ type: MessageTypes, id: string }>({ type: "txt", id: "" })
    const messagesEndRef = useRef<null | HTMLDivElement>(null);


    const checkMessageType = (txt: string): {
        type: MessageTypes,
        id: string
    } => {
        if (txt.includes("/home/expand/song/songId=")) {
            let regex = /songId=(\d+)/;
            let match = txt.match(regex);
            let songId = "0"
            if (match) {
                songId = match[1];
            }
            return {
                id: songId,
                type: "track"
            }
        } else if (txt.includes("/home/charts/videos?id=")) {
            let regex = /id=(\d+)/;
            let match = txt.match(regex);
            let videoId = "0"
            if (match) {
                videoId = match[1];
            }
            return {
                id: videoId,
                type: "video"
            }
        } else if (txt.includes("/creatives/beats?creative_id=")) {
            let regex = /creative_id=(\d+)/;
            let match = txt.match(regex);
            let creativeId = "0"
            if (match) {
                creativeId = match[1];
            }
            return {
                id: creativeId,
                type: "profile"
            }
        } else {
            return {
                id: "",
                type: "txt"
            }
        }
    }


    useEffect(() => {
        let msgData = checkMessageType(chatData.text)
        if (msgData.type != "txt") {
            setMessageType(msgData)
        }
        return () => {
        }
    }, [])

    useEffect(() => {
        scrollToBottom()
    }, [chatData])

    const redirectProfile = (currentuserType:any,currentUserId:any) => {
        if (currentuserType == "creative") {
          history.push(`/creatives/beats?creative_id=${currentUserId}`)
        } else if (currentuserType == "listener") {
          history.push(`/listeners/activity?listener_id=${currentUserId}`)
        } else {
          history.push(`/originals/beats?originals_id=${currentUserId}`)
        }
    
      }

    
      let profile_img: any = localStorage.getItem("profile_img")

      if(profile_img=="null"){
        profile_img=defaultProfile
    }
    const user_id: any = localStorage.getItem("user_id")
    const returnMessageFormat = () => {
        let returnMessageFormat
        if (messageType.type == "txt") {
            returnMessageFormat = <TextMsgCard
                isRecived={chatData.isRecived}
                avtar={chatData.isRecived ? chatData.sender.avtar : profile_img}
                index={chatData}
                senderName={chatData}
                timetoken={chatData.timetoken}
                text={chatData.text}
                particularUserDataRes={particularUserDataRes}
                onRedirect={(userType:any,userId:any)=>{
                    redirectProfile(userType,userId)
                }}
            />
        } else if (messageType.type == "track" || messageType.type == "video") {
            returnMessageFormat = <TrackMsgCard
                isRecived={chatData.isRecived}
                avtar={chatData.isRecived ? chatData.sender.avtar : profile_img}
                index={chatData}
                isTrack={messageType.type == "track"}
                senderName={chatData}
                timetoken={chatData.timetoken}
                text={chatData.text}
                trackId={messageType.id}
                userid={user_id}
                onRedirect={(userType:any,userId:any)=>{
                    redirectProfile(userType,userId)
                }}
                particularUserDataRes={particularUserDataRes}
                isChartMessage={isChartMessage}
            />
        } else if (messageType.type == "profile") {
            returnMessageFormat = <ProfileMsgCard
                isRecived={chatData.isRecived}
                avtar={chatData.isRecived ? chatData.sender.avtar : profile_img}
                index={chatData}
                senderName={chatData}
                timetoken={chatData.timetoken}
                text={chatData.text}
                userId={messageType.id}
                onRedirect={(userType:any,userId:any)=>{
                    redirectProfile(userType,userId)
                }}
                particularUserDataRes={particularUserDataRes}
                isChartMessage={isChartMessage}
            />
        } else {
            returnMessageFormat = <TextMsgCard
                isRecived={chatData.isRecived}
                avtar={chatData.sender.avtar}
                index={chatData}
                senderName={chatData}
                timetoken={chatData.timetoken}
                text={chatData.text}
                onRedirect={(userType:any,userId:any)=>{
                    redirectProfile(userType,userId)
                }}
                particularUserDataRes={particularUserDataRes}

            />
        }
        return returnMessageFormat
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth',
        block: 'end', // Scroll to the bottom of the container
        inline: 'nearest', // Crop from the top
    })
    }
    return (
        <>
            <div ref={messagesEndRef} />
            {returnMessageFormat()}
        </>
    )
}
// Customizable Area End