// Customizable Area Start
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';
import { Col, Row } from 'antd';
import { openNotification } from '../../../../components/src/Notification.web';
import { loadStripe } from "@stripe/stripe-js";
const urlConfig = require("../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

const setKey = () => {
    if(urlConfig.baseURL.includes("prod") ){
        return `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}`
    }else{
        return `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`
    }
    //return `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}`
}

const baseStripePromise: any = setKey()
// baseUrl.includes("prod") ? `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}` : `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`
const stripePromise = loadStripe(baseStripePromise);
export default function AlertDialog({ open, handleClose, dialogText, OpenUpgradModal, handleCloseOpenUpgradModal,

}: any) {
    //    const [openUpgradModal, setOpenUpgradModal] = useState<any>(false);
    // const handleCloseOpenUpgradModal = ()=>{
    //     set
    // }
    const token: any = localStorage.getItem("token")
    let [planData, setplanData] = useState([]);
    const [stripeId, setStripeId] = useState<any>('');

    const history = useHistory();

    // useEffect(() => {
    //     getSubcriptionPlan(token)
    // }, []);
    const subscription_plans: any = localStorage.getItem("subscription_plans")

    // const [currPlan, setCurrPlan] = useState<any>(subscription_plans)
    const [electedPlanData, setElectedPlanData] = useState<any>(subscription_plans);

    const getSubcriptionPlan = async (token: any) => {
        if (token) {
            await axios.get(`${baseUrl}/bx_block_subscription_plan/subscription_plans`, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    
                    token: token,
                }
            })
                .then((response) => {
                    setplanData(response?.data?.data)
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    // setLoading(false);
                });
            document.getElementById('continue')?.setAttribute('style', 'color:brown')
        } else {
            history.push("/Welcome");
        }
    }
    const selectPlan = (id: any, stripeId: any) => {
        setStripeId(stripeId);
        setElectedPlanData(id)
        // setenablePlanData(false)
        document.getElementById('plan' + id)?.setAttribute('style', 'background-color:#3131d7')
        // document.getElementById('continue')?.setAttribute('style', 'color:white')
    }
    const handleSubscribe = async () => {
        const data = {
            success_url:
                "https://nxzsound10-66173-react-native.b66173.dev.eastus.az.svc.builder.cafe/ListenerProfile?session_id={CHECKOUT_SESSION_ID}",
            cancel_url: "https://nxzsound10-66173-react-native.b66173.dev.eastus.az.svc.builder.cafe/ForListener",
            price: stripeId,
            device_type: "web",
            quantity: 1
        }
        await axios
            .post(`${baseUrl}/bx_block_subscription_plan/checkout_session`, data, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    
                    token: token,
                }
            })
            .then(async (res) => {
                console.log(res.data.session_id.id, "stsststtststststtststtststtstt");
                const stripe: any = await stripePromise;
                // eslint-disable-next-line
                const { error }: any = await stripe
                    .redirectToCheckout({
                        sessionId: res.data.session_id.id,
                    })
                    .then(async function (result: any) {
                        console.log("loginAlert");
                    });
                console.log(error, "Error");

                // history.push("/ListenerProfile")
            })
            .catch((error) => {
                console.log(error, "bbnb");

                // openNotification(err?.response?.data?.errors[0]?.token, "Error")
                openNotification(error?.response?.data?.message, "Error")
            });
    }

    const acceptPlan = async () => {

        const data = {
            // subscription_plan_id: electedPlanData,
            // stripe_plan_id: stripeId
        }
        await axios
            .post(`${baseUrl}/bx_block_subscription_plan/subscribe`, data, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    
                    token: token,
                }
            })
            .then((res) => {
                // {electedPlanData == "59" ?
                // history.push("/ListenerProfile") :handleSubscribe() }

                // history.push("/ListenerProfile")
            })
            .catch((err) => {

                // openNotification(err?.response?.data?.errors[0]?.token, "Error")
            });
    }

    const handleUpgradeModal = () => {
        handleClose()
        history.push('/planpayments')
    }
    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{
                    style: {
                        backdropFilter: "blur(10px)",
                        backgroundColor: "rgba(0, 0, 0, 0.15)",
                    },
                }}
                PaperProps={{
                    style: {
                        backgroundColor: "#121212",
                        borderRadius: "20px",
                        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
                        border: "solid 1px #1f1f1f",
                        width: "600px",
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" className="dialog-title repost-head" style={{ textAlign: "center", fontWeight: "normal" }}>
                    <span style={{
                        fontSize: "19px",
                        fontWeight: 800,
                        color: "white"
                    }}> NXZSound10</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description ">
                        <span style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "white"
                        }}>
                            {dialogText === "2" ? "Get a personalized experience, and access all your music." : dialogText === "1" ? "This is not implemented yet" : dialogText === "3" ? "Please Purchase Premium Account" : dialogText}
                        </span>
                    </DialogContentText>


                    {dialogText == "2" && (
                        <DialogContentText id="alert-dialog-description" className=" repost-head">
                            To access this feature,
                            go to <span style={{ fontSize: "14px", fontWeight: 600, color: "red" }}>LOGIN</span> screen.

                        </DialogContentText>
                    )}
                </DialogContent>
                {dialogText == "2" ? (
                    <DialogActions>
                        <Link to="/Welcome"><Button color="primary">
                            LOGIN
                        </Button>
                        </Link>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            GO BACK
                        </Button>
                    </DialogActions>
                ) : dialogText === "3" ?
                    (<DialogActions>
                        {/* <Link to="/Welcome"> */}
                        <Button color="primary" onClick={handleUpgradeModal}>
                            Upgrade plan
                        </Button>
                        {/* </Link> */}
                        <Button onClick={handleClose} color="primary" autoFocus>
                            GO BACK
                        </Button>
                    </DialogActions>)
                    : (
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" autoFocus>
                                Close
                            </Button>
                        </DialogActions>

                    )}
            </Dialog>
            <Dialog
                open={OpenUpgradModal}
                onClose={handleCloseOpenUpgradModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    style={{ backgroundColor: "#d5d0d0" }}
                    id="alert-dialog-title">{"NXZSound10"}</DialogTitle>
                <DialogContent style={{ backgroundColor: "#d5d0d0" }}>
                    <DialogContentText className='font_W_600' style={{ color: "black" }} >
                        Upgrade Your Plan

                    </DialogContentText>
                    <DialogContentText className='text_white'>
                        {/* <Row className='width_100' style={{ backgroundColor: "red", color: "white" }}> */}
                        {
                            planData && planData.map((plan: any) =>
                                <Row
                                    id={'plan' + plan?.attributes?.name}
                                    // onMouseEnter={() => setBgColour("#c83f49")}
                                    // onMouseLeave={() => setBgColour("#fafafa")}
                                    onClick={() => {
                                        // if (electedPlanData) {
                                        document.getElementById('plan' + electedPlanData)?.setAttribute('style', 'background-color:rgb(63, 63, 63)')
                                        // }
                                        selectPlan(plan?.attributes?.name, plan?.attributes?.stripe_plan_id)
                                    }}
                                    key={plan.attributes.id} className='width_100 mb_1' style={{ backgroundColor: "#454545", width: "270px", color: "white", padding: "10px 7px", borderRadius: "12px" }}><Col span={10}>{plan.attributes.name}</Col><Col span={14} >
                                        <Row justify='center'>${plan?.attributes?.amount + '/' + plan?.attributes?.interval}
                                            {/* {plan.attributes.amount} */}
                                        </Row></Col>
                                </Row>
                            )
                        }
                        {/* </Row> */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#d5d0d0" }}>
                    {/* <Link to="/Welcome"> */}
                    <Button style={{ color: "black" }} onClick={acceptPlan} >
                        Subscribe
                    </Button>
                    {/* </Link> */}
                    <Button onClick={handleClose} style={{ color: "black" }} autoFocus>
                        GO BACK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
// Customizable Area End