// Customizable Area Start
import React, { useEffect, useRef, useState } from 'react'
import { Grid, Avatar } from '@material-ui/core';
import '../../../blocks/dashboard/src/Charts/Videos/MessageSection.web.css'
import { send_icon } from '../../../blocks/dashboard/src/assets';
import { usePubNub } from 'pubnub-react';
import axios from 'axios';
import { IMessage, ISender } from 'blocks/Sms/src/CustomMessageInterface';
import { getChannelName, readMessage,sendMessage } from 'blocks/Sms/src/ChatUtils';
import ViewNewMessengerProfile from 'blocks/Sms/src/ViewNewMessengerProfile.web';
import MessageCard from 'blocks/Sms/src/MessageCard.web';

const urlConfig = require("../../../framework/src/config");
const baseUrl = urlConfig.baseURL;

export default function MessageSection({ setMessageCount, trackId }: any) {
  const [input, setInput] = useState<string>('');
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [getUserDetails, setGetUserDetails] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false);
  const [avtarUrl, setAvtarUrl] = useState('');
  const currentUserId = Number(localStorage.getItem("loggedIn_userId"))
  const userId = trackId?.attributes?.account_id || trackId?.attributes?.artist_id

  const ChannelName = getChannelName(String(currentUserId), userId);

  const pubnub = usePubNub();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const scrollableDiv = useRef<HTMLDivElement>(null);


  console.log(isLoading,"trackId")
  useEffect(() => {
    if (userId) {
      _getMessages();
      _getUserData();
      getUserAvtar();
      readMessage(userId)

    }


  }, [userId])


  const convertChatResponseToPubNub = (messages: any[]): IMessage[] => {
    return messages?.map((message) => {
      const {
        id,
        attributes: {
          profile_image: avtar,
          artist_name: name,
          message: text,
          // read_status: isRead,
          message_time: timestamp,
          sender_id: senderId,
          // receiver_id: receiverId,
          receiver
        }
      } = message;


      const sender = {
        id: senderId,
        name,
        avtar
      };


      return {
        id,
        isRecived: receiver,
        sender,
        text,
        timetoken: new Date(timestamp).getTime() * 10000
      };
    }) as any;
    // }
  }






  const _getMessages = async () => {
    setIsLoading(true)
    axios.get(
      `${baseUrl}/bx_block_messages/messages/user_chat_screen?sender_id=` + userId,
      {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      }
    )
      .then((responseJson) => {
        setIsLoading(false)
        if (responseJson?.data?.data) {


          setMessages(convertChatResponseToPubNub(responseJson.data.data))
          setMessageCount(responseJson.data.data.length)
        } else {
          setMessages([])
        }


      })
      .finally(() => {
        setIsLoading(false)
      });
  };


  const getUserAvtar = async () => {
    const profilePic = localStorage.getItem("profile_img")
    if (profilePic !== null) {
      setAvtarUrl(profilePic);
    }
  };




  const _getUserData = async () => {
    axios.get(
      `${baseUrl}/bx_block_profile/user_profile?id=` + userId,
      {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      }
    )
      .then((responseJson) => {
        if (responseJson.data) {
          setGetUserDetails(responseJson.data.data)
        }
      });
  };



  useEffect(() => {
    if (pubnub) {
      pubnub.setUUID(currentUserId + "");
      const listener = {
        message: (envelope: any) => {
          setMessages(msgs => [
            ...msgs,
            {
              id: messages.length + 1,
              isRecived: currentUserId != envelope.publisher,
              sender: envelope.message.content.sender as ISender,
              text: envelope.message.content.text + "",
              timetoken: envelope.timetoken
            } as unknown as IMessage
          ]);
          if (currentUserId != envelope.publisher) {
            readMessage(userId)
          }
        },
      };


      pubnub.addListener(listener);
      pubnub.subscribe({ channels: [ChannelName] });


      return () => {
        pubnub.removeListener(listener);
        pubnub.unsubscribeAll();
      };
    }
  }, [pubnub]);
  const token: any = localStorage.getItem("token")


  const onSend = (msgTxt: string) => {
    if (msgTxt == "") {
      return
    }


    const message = {
      content: {
        text: msgTxt.trim(),
        sender: {
          id: currentUserId,
          name: "",
          avtar: avtarUrl,
        }
      },
      type: '',
      id: crypto.getRandomValues(new Uint8Array(16))
      // id: Math.random().toString(16).substr(2)
    };
    sendMessage(userId, msgTxt)
    pubnub.publish({ channel: ChannelName, message }, function (status: any, response: any) {
    });


  }
  const handleSearchInput = () => {
    if (scrollableDiv.current) {
      scrollableDiv.current.scrollTop = scrollableDiv.current.scrollHeight;
    }
    setInput('')
  }

  const renderMessageContent = () => {


    if (userId === currentUserId) {
      return (
        <div style={{ marginTop: "7%" }}>
          <p className='showPrivateMessageList'>You can't message on your own content! Message</p>
        </div>
      );
    }

    if (messages.length > 0) {
      return (
        <>
          <div ref={scrollableDiv} style={{ justifyContent: "flex-start", width: "100%", height: "100%" }} className="chatgridHeadPrivateDM">
            {messages.map((chatData) => (
              <MessageCard chatData={chatData} isChartMessage={true} />
            ))}
          </div>
          <div style={{ float: "left", clear: "both" }} ref={messagesEndRef} />
        </>
      );
    }

    return <ViewNewMessengerProfile userData={getUserDetails} isExpand={true} />;
  };

  return (

    <>
      <Grid
        container
        xs={12}
        style={{
          backgroundColor: '#000000',
          padding: '20px',
          borderRadius: '15px',
          height: "420px"
        }}>
        <Grid item xs={12} container spacing={2}>
          <Grid
            item
            xs={1}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Avatar
              alt='Remy Sharp'
              src={trackId?.attributes.artist_image}
              className="comment_avatar"
            />
          </Grid>
          <Grid
            item
            xs={11}
            style={{ position: 'relative', paddingLeft: '15px' }}>

            <div className='comment_send_display'>
              <input
                placeholder={`Send Message to @${trackId?.attributes.artist_name}...`}
                className='addComment_msg add_Comment_Input disable_resize'
                value={input}
                onChange={(e: any) => setInput(e.target.value)}
                onKeyPress={(event: any) => {
                  if (event.key === 'Enter') {
                    onSend(input)
                    setInput('')
                  }
                }}
              />
              <button className='comment_button' onClick={() => {
                onSend(input);
                handleSearchInput()
              }}
                style={{ cursor: input != "" ? "pointer" : "not-allowed" }}
              >
                Send
              </button>
              <img src={send_icon} alt="send button" className='comment_send_button comment_avatar' />
            </div>
            <div className='comment_input_border'></div>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          className='commentBlock'
          style={{ padding: '12px', margin: '10px 0px' }}>

          {renderMessageContent()}
        </Grid>
      </Grid>
    </>
  );
}
// Customizable Area End