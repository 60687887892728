// Customizable Area Start
import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from '@material-ui/core';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { openNotification } from "../../../components/src/Notification.web";
import { AudioContextApi } from "../../dashboard/src/AudioContext/AudioContext.web";
import { defaultProfile ,playButton} from "../../dashboard/src/assets";
export const configJSON = require("./config.js");

interface ITrackMsgCard {
   isRecived: boolean,
   avtar: string,
   index: number,
   senderName: string,
   timetoken: string,
   text: string,
   isTrack: boolean,
   trackId: string,
   userid:any;
   particularUserDataRes:any;
   isChartMessage?:any;
   onRedirect : (currentuserType: any,currentUserId:any)=>void;
   // moveScreen: any
}

const ProfileTrackImg = ({ image, isTrack,handleTrackAndVideo }: { image: string, isTrack: boolean,handleTrackAndVideo:any }) => {
   return (
       <Box style={{ justifyContent: "center", alignItems: "center", display: "flex", position: "relative" }}>
           <img
                className={isTrack?'trackMsgCard-isTrack':'trackMsgCard-noTrack'}
               src={image}
           />
           <img
               style={{
                   height: 42,
                   width: 42,
                   position: "absolute",
                   cursor:"pointer"
               }}
               onClick={handleTrackAndVideo}
               src={playButton}
           />
       </Box>
   )
}




function TrackMsgCard(props: ITrackMsgCard) {
    const { isRecived, avtar, isTrack, trackId, onRedirect,particularUserDataRes,isChartMessage } = props
   const [trackData, setTrackData] = useState<any>("")
   const history = useHistory();


   useEffect(() => {
       _getTrackData()
   }, [])
   const token: any = localStorage.getItem("token")
   const user_type: any = localStorage.getItem("user_type")
   const user_id: any = localStorage.getItem("loggedIn_userId")

   const _getTrackData = async () => {
       axios.get(
           `${configJSON.baseUrl}/bx_block_audiomusic/show_track?id=` + trackId,
           {
               headers: {
                   "Content-Type": "application/json",
                   token: token,
               },
           }
       )
           .then((responseJson) => {
               if (responseJson.data) {
                   setTrackData(responseJson.data.data[0])
               } else {
                openNotification("No Data Found","Error")
            }
           });
   };


  
   const { handlePlayList } = useContext(AudioContextApi)


   const handleTrackAndVideo = () => {
       if (isTrack) {
           handlePlayList(trackData, 0, trackData, trackData.track_upload_type, "1")
       } else {
           history.push({
               pathname: '/home/Charts/videos',
               state: { video_list: trackData.video_file, activeNo: "5" }
           })
       }
   }
 
   let currentuserType=isRecived?particularUserDataRes?.attributes?.artist_role:user_type
   let currentUserId=isRecived?particularUserDataRes?.id:user_id

   
return (
       <div
       style={{ height: isChartMessage?"none":"100%", width: "100%", padding: "5px 0px 20px 30px", display: "flex", alignItems: isRecived ? "flex-start" : "flex-end" }}>
           <Box style={{
               height: "100%", width: "100%", borderRadius: 15, backgroundColor: "#141414",
               position: "relative",
               padding: "10px"
           }}>
               <img
                   style={{
                       height: 60,
                       width: 60,
                       borderRadius: 60,
                       borderWidth: 5,
                       borderColor: "#141414",
                       position: "absolute",
                       marginTop: isTrack ? 140 : 85,
                       marginLeft: -30,
                       zIndex: 99,
                       backgroundColor: "#141414",
                       padding: 10
                   }}
                   onClick={()=>    onRedirect(currentuserType,currentUserId)}
                   src={avtar ? avtar : defaultProfile}
               />


               <Box style={{ flexDirection: "row", alignItems: "center", display: "flex" }}>
                   <img
                       style={{
                           height: 29,
                           width: 29,
                           borderRadius: 29,


                       }}
                       src={trackData?.attributes?.artist_image ? trackData?.attributes?.artist_image : ""}
                   />
                   <Box style={{ marginLeft: 10 }}>
                   <Typography style={{ color: "#fff", fontSize: 12, fontWeight: "bold" }}>{trackData?.attributes?.artist_name}</Typography>
                   </Box>
               </Box>
               <Box style={{ margin: 10 }}>
               <ProfileTrackImg isTrack={isTrack} image={trackData?.attributes?.art_work} handleTrackAndVideo={handleTrackAndVideo} />
               </Box>
               <div
                   style={{
                       height: 33,
                       backgroundColor: "#000", borderRadius: 33, justifyContent: "center", alignItems: "center", display: "flex"
                   }}


               >
                   <Typography style={{ color: "#fff", fontWeight: "bold", cursor: "pointer" }} onClick={handleTrackAndVideo}>{isTrack ? "Listen Now" : "Watch Now"}</Typography>
               </div>
           </Box>


       </div>
   )
}






export default TrackMsgCard;
// Customizable Area End