// Customizable Area Start
import { Content } from 'antd/lib/layout/layout'
import { Layout, Typography ,Tabs} from 'antd';
import {
    Hidden,
} from "@material-ui/core";
import React, { useState } from 'react'
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import DashboardRevenue from './DashboardRevenue/DashboardRevenue.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import DashboardAnalytics from './DashboardAnalytics/DashboardAnalytics.web';
import DashboardWallet from './DashboardWallet/DashboardWallet.web';
import MusicSplitDeal from './DashboardDeal/MusicSplitDeal/DashboardMusicdeal';
const { TabPane } = Tabs;

function DashboardPage() {

    const [active, setActive] = useState("1");


    const callback = (key: any) => {
        console.log(key);
        setActive(key);
    };

    const screen = () => {
        if (active === "" || active === "1") {
            return <DashboardRevenue  />;
        }
        if (active === "2") {
            return <DashboardAnalytics navigation={undefined} id={''}  />;
        }
        if (active === "3") {
            return <DashboardWallet navigation={undefined} id={''} />
        }
        if (active === "4") {
            return <MusicSplitDeal navigation={undefined} id={''} />
        }

    };

    return (
        <Layout style={{ backgroundColor: "black", height: "100vh", overflowY: 'scroll' }}>
            {/* .......................SideBar...................................... */}
            <Hidden smDown>
                <AsideLeft />
            </Hidden>
            <Content
                style={{
                    margin: "0px",
                    // height: "100%",
                    backgroundColor: "black",
                    display: "flex", flexDirection: "column",
                    // justifyContent: "space-between"
                }}
            >
                <HomeHeader />
                <Typography style={{ margin: '15px', fontSize: '32px', color: 'white', fontWeight: 'bold' }}>
                    Dashboard
                </Typography>

                <div className="chats-header" style={{ marginLeft: '15px' }}>
                    <Tabs
                        id="search_custom_tab"
                        activeKey={active}
                        // className="tab-body"
                        onChange={callback}
                    >
                        <TabPane className="tab-pane" tab="Revenue" key="1" />

                        <TabPane tab="Analytics" key="2" />

                        <TabPane tab="Wallet" key="3" />

                        <TabPane tab="Deals" key="4" />

                    </Tabs>
                </div>
                {screen()}
            </Content>
        </Layout>

    )
}

export default DashboardPage
// Customizable Area End