// Customizable Area Start
import {  Grid, Box } from "@material-ui/core";
import React from "react";
import Buttongroup from "../Common/Buttongroup.web";
import FilterDropDown from "components/src/CustomChart/PopUps/FilterDropDown.web";
import { album1,shareWallet,receiveWallet,cashout} from "blocks/dashboard/src/assets";
import "./DashboardWallet.css"
import DashboardWalletcontroller from "./DashboardWalletcontroller.web";





export default class DashboardWallet extends DashboardWalletcontroller {
    constructor(props: any) {
        super(props);

    }

    // Customizable Area Start
    showButtonGroup = () => {
       
      return (
        <Grid item md={2} xs={6} lg={2} className="audio-div">
          <Buttongroup
            buttonshow={false}
            musicButton={this.state.musicButton}
            setMusicButton={() => {
              this.setVideoButtonTrue()
            }}
            setMusicButtonTrue={() => {
              
               this.setMusicButtonTrue()
            }}
            setVideoButtonTrue={()=>{
              this.setUserButtontrue()
            }} />
            
        </Grid>
      )
    
  }
    // Customizable Area End

    render() {
    const audioWallet=()=>{
        return (
          <Box style={{padding: "0px 52px 0px 31px"}}>
           
          <Grid item xs={12} style= {{ backgroundColor: "black" }}   className= "filter-div filter_align_right" >
             <Grid style={{justifyContent:"flex-end", display: "flex",paddingBottom:"30px" }} alignItems="flex-end">
               <div >
                {this.showButtonGroup()}
               </div>
               <div style={{ position: "relative" }}>
               <FilterDropDown filterValue="Filter"
                />
               </div>
             </Grid>
           </Grid>
           <Box className="wallettop_container">
               <Box className="wallet_container_element">
                 <Box style={{display: "flex",justifyContent: "space-between"}}>
                 <span className="Music-Royalties">
                         Music Royalties
               </span>
               <span className="This-Week">
                This Week
             </span>
                 </Box>
                 <Box style={{marginTop:"10px",height: "693px",overflowX: "scroll"}}>
                   {Array.from({ length: 15 }).map((item, index) => <Box className="wallet_leftside_container">
                       <Box style={{ display: "flex", gap: "10%",alignItems: "center" , justifyContent: "center"}}>
                           <img src={album1} className="iconImg" style={{ borderRadius: '10px' }}
                              height="60px"
                              width="60px"
                             />
                  <Box style={{gap:'10px',display:'flex',flexDirection:'column'}}>
                          <span className="Swalla">
                          Swalla
                          </span>
                          <span className="Jason-Derulo">
                          Jason Derulo
                         </span>
                          </Box>   
                       </Box>
                       <Box style={{gap:"8px", display: "flex", flexDirection: "column" }}>
                       <span className="money">
                        $357.95
                     </span>
                  <span>
                     <span className="text-style-1">-</span>630%
                     </span>
                       </Box>
                   </Box>)}


               </Box>
               </Box>
               <Box style={{alignItems:"center",width:"50%",display:"flex",justifyContent:"end"}} className="rightsidecontainer">
               <Box className="iaiaiaiai" style={{ backgroundImage: "linear-gradient(to bottom, #ff0010 0%, #000000 68%)"}} >
                 <Box style={{height:"80%",background:"black",width:"80%"}}>
                 <Box className='revenue_right_box'>
              <div  className='revenue_balance'>
                 <span>BALANCE</span>
                 </div>
                <div>
                  <span className="revenue_amount">
                $10,326,58<span style={{fontSize:"22px"}}>.15</span>
            </span>
            </div>
               <div style={{display:"flex",gap:"3%"}}>
                <div>
                <img src={shareWallet} className="shareButton" alt="name"/>
                <span className="Send">
                  Send
                 </span>
                </div>
                <div>
                <img src={receiveWallet} className="shareButton"/>
                <span className="Send ">
                  Recieve
                 </span>
                </div>
                <div>
                <img src={cashout} className="shareButton"/>
                <span className="Send">
                 Cashout
                 </span>
                </div>
              </div>
          </Box>
                 </Box>

               </Box>
               </Box>
           </Box>
         </Box>
        )
      }

    const videoWallet=()=>{
        return (
          <Box style={{padding: "0px 52px 0px 31px"}}>  
          <Grid  className= "filter-div filter_align_right" style={{ backgroundColor: "black" }} item xs={12}   >
             <Grid alignItems="flex-end"style={{ display: "flex",justifyContent:"flex-end",paddingBottom:"30px" }} >
               <div >
                 {this.showButtonGroup()}
               </div>

               <div style={{ position: "relative" }}>

               <FilterDropDown filterValue="Filter"
                 />
               </div>
             </Grid>
           </Grid>
                        <Box className="wallettop_container">
                               <Box className="wallet_container_element">
                 <Box style={{display: "flex",justifyContent: "space-between"}}>
                 <span className="Music-Royalties">
                           Video Royalties
               </span>
             <span className="This-Week">
                          This Week
             </span>
                 </Box>
                 <Box style={{overflowX: "scroll",height: "693px",marginTop:"10px"}}>
                   {Array.from({ length: 10 }).map((item, index) => <Box className="wallet_leftside_container">
                       <Box style={{ display: "flex", gap: "10%", justifyContent: "center", alignItems: "center" }}>
                           <img src={album1} className="iconImg" style={{ borderRadius: '11px' }}
                              width="60px"  
                               height="60px"
                                 
                           />

                           <Box style={{flexDirection:'column',display:'flex',gap:'10px'}}>
                           <span className="Swalla">
                           Swalla
                          </span>
                          <span className="Jason-Derulo">
                          Jason Derulo
                         </span>
                          </Box>   
                       </Box>
                       <Box style={{ flexDirection: "column",display: "flex",gap:"8px" }}>
                       <span className="money">
                        $357.95
                     </span>
                  <span>
                     <span className="text-style-1">-</span>60%
                     </span> 
                       </Box>
                   </Box>)}
               </Box>
               </Box>
               <Box style={{display:"flex",width:"50%",justifyContent:"end",alignItems:"center"}} className="rightsidecontainer">
               <Box style={{ backgroundImage: "linear-gradient(to bottom, #ff0010 0%, #000000 68%)"}} className="iaiaiaiai">
                 <Box style={{background:"black",width:"80%",height:"80%"}}>
                 <Box className='revenue_right_box'>


              <div  className='revenue_balance'>
                 <span>BALANCE</span>
                 </div>
                <div>
                  <span className="revenue_amount">
                $10,3876,658<span style={{fontSize:"22px"}}>.19</span>
            </span>
            </div>
               <div style={{display:"flex",gap:"3%",margin:"0"}}>
                <div>
                <img src={shareWallet} className="shareButton"/>
                <span className="Send">
                  Send
                 </span>
                </div>
                <div>
                <img src={receiveWallet} className="shareButton"/>
                <span className="Send">
                  Recieve
                 </span>
                </div>
                <div>
                <img src={cashout} className="shareButton"/>
                <span className="Send">
                 Cashout
                 </span>
                </div>
              </div>
          </Box>
                 </Box>
               </Box>
               </Box>  
           </Box> 
         </Box>
        )
      }

    const userWallet=()=>{
      return (
         <Box style={{padding: "0px 52px 0px 31px"}}>
           


        <Grid  item xs={12}  className= "filter-div filter_align_right" style={{ backgroundColor: "black" }}>
            <Grid style={{justifyContent:"flex-end",paddingBottom:"30px", display: "flex" }} alignItems="flex-end">
              <div >
                 {this.showButtonGroup()}
               </div>
               
               <div style={{  position: "relative" }}>
               <FilterDropDown filterValue="Filter"
                 />
               </div>
             </Grid>
           </Grid>
               <Box className="wallettop_container">
               <Box className="wallet_container_element">
                 <Box style={{display: "flex",justifyContent: "space-between"}}>
                 <span className="Music-Royalties">
                    Transactionss
               </span>
             <span className="This-Week">
                This Week
             </span>
                 </Box>
                 <Box style={{overflowX: "scroll",marginTop:"10px",height: "693px",}}>
                   {Array.from({ length: 20 }).map((item, index) => <Box className="wallet_leftside_container">
                       <Box style={{gap: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                           <img src={album1} className="iconImg" style={{ borderRadius: '10px' }}
                               height="60px"
                               width="60px"
                           />
                           <Box style={{gap:'10px',display:'flex',flexDirection:'column'}}>
                           <span className="Swalla">
                           Metro Bommbin
                          </span>
                          <span className="Jason-Derulo">
                          For: Studio Time
                         </span>
                           </Box>      
                       </Box>
                       <Box style={{ display: "flex",gap:"8px",flexDirection: "column" }}>
                       <span className="money ok">
                        $357.95
                     </span>
                  <span>
                     <span className="text-style-1">-</span>630%
                     </span>
                       </Box>
                   </Box>)}


               </Box>
               </Box>
               <Box style={{display:"flex",justifyContent:"end",alignItems:"center",width:"50%"}} className="rightsidecontainer">
               <Box style={{ backgroundImage: "linear-gradient(to bottom, #ff0010 0%, #000000 68%)"}} className="iaiaiaiai">
                 <Box style={{background:"black",height:"80%",width:"80%"}}>
                 <Box className='revenue_right_box'>
              <div  className='revenue_balance'>
                 <span>BALANCE</span>
                 </div>
                <div>
                  <span className="revenue_amount">
                $10,326,658<span style={{fontSize:"22px"}}>.194</span>
            </span>
            </div>
               <div style={{display:"flex",gap:"3%"}}>
                <div>
                <img src={shareWallet} className="shareButton"/>
                <span className="Send o">
                  Send
                 </span>
                </div>
                <div>
                <img src={receiveWallet} className="shareButton"/>
                <span className="Send o">
                  Recieve
                 </span>
                </div>
                <div>
                <img src={cashout} className="shareButton"/>
                <span className="Send">
                 Cashout
                 </span>
                </div>
              </div>
          </Box>


                 </Box>
               </Box>
               </Box>  
           </Box>
         </Box>
        )
      }
      const compRender = () => {
        switch (this.state.musicButton) {
            case 1:
                return audioWallet()
            case 2:
                return videoWallet()
            default:
                return userWallet()
        }
    }
        return (
            //Merge Engine DefaultContainer
         compRender()

            //Merge Engine End DefaultContainer
        );
    }
}
// Customizable Area End