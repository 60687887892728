// Customizable Area Start
import React from 'react'
import { Grid,  Button, Typography } from "@material-ui/core";
import { Col, Row} from "antd";
import './SearchUsers.css'

import {  plays, reshared, commentBlue } from '../../../../dashboard/src/assets'
import { defaultUserImage } from '../../assets';

const SearchMobileUsers = (props: any) => {
    const {user, redirectProfile, followingUsers, followUser, unFollowUser  } = props;
    return(
        <>
        <Grid container  spacing={1} className='main-grid hide_in_medium_and_large_search_users' style={{overflowX:'hidden', padding: '10px' ,backgroundColor:'#1a1a1a', borderRadius: '20px', border: "1.5px solid rgb(50 49 49)",flexDirection: 'column'}}>
      <Grid style={{display: 'flex',flexDirection: 'row'}}>
   
      <Grid item  >
        <img test-id="search_users_redirect_test_id_0" src={user?.attributes?.avatar ? user?.attributes?.avatar : defaultUserImage} className="user-profile" style={{borderRadius: '50%', cursor: 'pointer', objectFit: 'cover', width: '90px', height: '90px'}}  onClick={()=> redirectProfile(user?.id,user?.attributes.user_type)}></img>
      </Grid>
     
      <Grid item  style={{display: 'flex', flexDirection: 'column', marginLeft: '10px'}}>
              <Grid  className='main-name-grid' test-id="search_users_redirect_test_id" style={{  cursor: 'pointer'}} onClick={()=> redirectProfile(user?.id,user?.attributes.user_type)} >
                <Typography className='user-name-size' style={{marginBottom: '5px'}} >{user?.attributes?.user_name}</Typography>
                <Typography style={{fontSize: '12px',color:'grey', marginLeft: '5px', marginRight: '2px'}}>{user?.attributes?.creative_type_name && <div style={{marginTop: '4px'}}>({user?.attributes?.creative_type_name})</div>}</Typography>
              </Grid>
              
              <Grid style={{display: 'flex'}}>
                <Typography style={{fontSize: '12px',color:'grey',marginRight: '5px'}}>{user?.attributes?.city}</Typography>
                <Typography style={{fontSize: '12px',color:'grey'}}>{user?.attributes?.country}</Typography>
              </Grid>
              
              <Grid className='icons-grid play-icons-margin-left' style={{display: 'flex' }}>
              <Col className="hiddn_countGrp">
                                  <Row className="display-user-icons " >
                                      <Col className="display" style={{ padding: "0px 3px" }}>
                                          <img src={plays} style={{ height: "20px", width: "25px", marginTop: "0px" }} />
                                          <p className="ml_05" style={{
                                              textAlign: "start",
                                              color: "#fff", fontSize: "10px", marginTop: '3px'
                                          }}>
                                              {user?.attributes?.play_count}
                                          </p>
                                      </Col>
                                      <Col className="display" style={{ padding: "0px 3px" }}>
                                          <img src={commentBlue} style={{ height: "17px", width: "17px", marginTop: '2px' }} />
                                          <p className="ml_05" style={{
                                              textAlign: "start",
                                              color: "#fff", fontSize: "10px", marginTop: '3px'
                                          }}>
                                              0
                                          </p>
                                      </Col>
                                      <Col className="display" style={{
                                          padding: "0px 3px"
                                      }}>
                                          <img src={reshared} style={{ height: "22px", width: "25px", marginTop: "-2px" }} />
                                          <p className="ml_05" style={{
                                              color: "#fff", fontSize: "10px", marginTop: '3px'
                                          }}>
                                            {user?.attributes?.repost_count}
                                          </p>

                                              </Col>
                                          </Row>
                                      </Col>
                                  </Grid>
                              </Grid>
                              </Grid>
                     {
                            (followingUsers.includes(user?.id)) &&
                            <Grid item  style={{marginLeft: '100px', width: '85px'}} >
                              <Button test-id="unfollow_user_in_search_test_id" className="follow-butt follow-icon-left follow-button-width" style={{color:'white',padding: '5px 20px',borderRadius: '20px',borderColor: 'rgb(12,13,13)', backgroundColor: 'rgb(12,13,13)', height: '25px', fontSize: '10px', marginLeft: '-10px', marginTop: '10px',textTransform: 'capitalize'}} onClick={() => {unFollowUser(user?.id)}}>UnFollow</Button>
                            </Grid>
                          }
                        {
                          (!followingUsers.includes(user?.id)) &&
                          <Grid item style={{marginLeft: '100px', width: '85px'}} >
                            <Button test-id="follow_user_in_search_test_id" className="follow-butt follow-icon-left follow-button-width" style={{color:'white',padding: '5px 20px',borderRadius: '20px',borderColor: 'red', backgroundColor: 'red', height: '25px', fontSize: '10px', marginLeft: '-10px', marginTop: '10px'}} onClick={() => {followUser(user?.id)}}>Follow</Button>
                          </Grid>
                        }
  

    </Grid>
        </>
    )
}
export default SearchMobileUsers
// Customizable Area End