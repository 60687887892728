// Customizable Area Start
import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Box } from '@material-ui/core'
// Customizable Area End


// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export const Search = (props: any) => {
    const handleSearch = (e: any) => {
        props.onSearchChange(e)
    }
    return (
        <Box className="SearchInputBox"
        style={{width:"100%"}}
        >
            <Input
                onChange={handleSearch}
                prefix={
                     // @ts-ignore
                    <SearchOutlined
                   
                        translate="true"
                        className="SearchText SearchIcon"
                        style={{
                        }}

                    />
                }
                style={{ width: "100%" }}
                placeholder={props.placeholder}
            />
        </Box>
    );
}
// Customizable Area End


// Customizable Area Start
export default Search;
// Customizable Area End
