// Customizable Area Start
import React, { useEffect, useState } from 'react'
import { Grid,  Button, Typography } from "@material-ui/core";
import { Col, Row} from "antd";
import axios from 'axios';
import './SearchUsers.css'

import {  plays, reshared, commentBlue, nxzloader } from '../../../../dashboard/src/assets'
import { openNotification } from '../../../../../components/src/Notification.web';
import { useHistory } from 'react-router-dom';
import { defaultUserImage } from '../../assets';
import SearchMobileUsers from './SearchMobileUsers.web'

const urlConfig = require("../../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;


function SearchUsers(props: any) {
    const [followingUsers, setFollowingUsers] = useState<any>([])

    const {allUsers, loading} = props;
   
    const history = useHistory()

    const token: any = localStorage.getItem("token")


    const followUser = async (id: any ) =>{
      await axios.get(`${baseUrl}/account_block/follow_user?user_id=${id}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token
        }
      })
        .then((response: any) => {
          setFollowingUsers(followingUsers.concat(id))
        openNotification('Following successful', 'Success')          
        }).catch((err: any) => {
          console.log(err.response, "eeeeeeeeeeeeeeeee");
  
        });
    }

    const unFollowUser = async(id: any) =>{
      await axios.get(`${baseUrl}/account_block/unfollow_user?user_id=${id}`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token
        }
      })
        .then((response: any) => {
          setFollowingUsers(followingUsers.filter((filterId :any) => filterId !== id))
          openNotification('unfollow successfull', 'Success')          
        }).catch((err: any) => {
          console.log(err.response, "eeeeeeeeeeeeeeeee");
  
        });
      console.log(id, 'unfollow id')
    }
    const redirectProfile = async(id :any,userType:any) =>{
      console.log(localStorage.getItem("user_id"), 'user_id in users')
       localStorage.removeItem("selectedTab")
      
        const ids: any = localStorage.getItem("user_id")
         localStorage.setItem("temp_id", ids)
         localStorage.removeItem("user_id")
         localStorage.setItem("user_id", id)
         localStorage.setItem("creatives_screen", "true")
         localStorage.setItem("current_type", userType);

      if(userType=="creative"){
      history.push(`/creatives/beats?creative_id=${id}`)
      } else if(userType=="listener"){
        history.push(`/listeners/activity?listener_id=${id}`)
      }else{
        history.push(`/originals/beats?originals_id=${id}`)
      }
  }
   
  useEffect(() =>{
    allUsers.map((user :any)=>{
      if(user?.attributes?.follow_status){
        followingUsers.push(user?.id)
      }
   })
    console.log(followingUsers ,'user followers ids')
  },[allUsers])



  return (
       <>
        {loading ? (

          <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
          <img src={nxzloader} style={{ width: "5%" }} />
          </Row>

        ) :

        <Grid   className="search-users-scroll">
            <Grid  style={{ marginRight: '10px', padding: '2px'}}>
        <Grid container item lg={12}  style={{ padding: '2px', marginRight: '10px'}}>
            {
                allUsers.length!==0 ?
                allUsers.map((user: any)=> {
                   return <>
                    <Grid item  xs={12} sm={12} md={6} lg={6} xl={6}style={{padding: '10px', borderRadius: '20px'}}>
                    <Grid container  spacing={1} className='main-grid hide_in_mobile_search_users' style={{overflowX:'hidden', padding: '10px' ,backgroundColor:'#1a1a1a', borderRadius: '20px', border: "1.5px solid rgb(50 49 49)"}}>
                     
                     
                        <Grid item xs={4} sm={3} >
                          <img test-id="search_users_redirect_test_id_0" src={user?.attributes?.avatar ? user?.attributes?.avatar : defaultUserImage} className="user-profile" style={{borderRadius: '50%', cursor: 'pointer', objectFit: 'cover'}}  onClick={()=> redirectProfile(user?.id,user?.attributes.user_type)}></img>
                        </Grid>
                        
                        <Grid item xs={12} sm={7} style={{display: 'flex', flexDirection: 'column'}}>
                                <Grid  className='main-name-grid' test-id="search_users_redirect_test_id" style={{ marginTop: '15px', cursor: 'pointer'}} onClick={()=> redirectProfile(user?.id,user?.attributes.user_type)} >
                                  <Typography className='user-name-size' style={{marginBottom: '5px'}} >{user?.attributes?.user_name}</Typography>
                                 
                                  <Typography style={{fontSize: '12px',color:'grey', marginLeft: '5px', marginRight: '2px'}}>{user?.attributes?.creative_type_name && <div style={{marginTop: '4px'}}>({user?.attributes?.creative_type_name})</div>}</Typography>
                                </Grid>
                                
                                <Grid style={{display: 'flex'}}>
                                  <Typography style={{fontSize: '12px',color:'grey',marginRight: '5px'}}>{user?.attributes?.city}</Typography>
                                  <Typography style={{fontSize: '12px',color:'grey'}}>{user?.attributes?.country}</Typography>
                                </Grid>
                                
                                
                                <Grid className='icons-grid play-icons-margin-left' style={{display: 'flex' }}>
                                <Col className="hiddn_countGrp">
                                                    <Row className="display-user-icons " >
                                                        <Col className="display" style={{ padding: "0px 3px" }}>
                                                            <img src={plays} style={{ height: "20px", width: "25px", marginTop: "0px" }} />
                                                            <p className="ml_05" style={{
                                                                textAlign: "start",
                                                                color: "#fff", fontSize: "10px", marginTop: '3px'
                                                            }}>
                                                                {user?.attributes?.play_count}
                                                            </p>
                                                        </Col>
                                                        <Col className="display" style={{ padding: "0px 3px" }}>
                                                            <img src={commentBlue} style={{ height: "17px", width: "17px", marginTop: '2px' }} />
                                                            <p className="ml_05" style={{
                                                                textAlign: "start",
                                                                color: "#fff", fontSize: "10px", marginTop: '3px'
                                                            }}>
                                                                0
                                                            </p>
                                                        </Col>
                                                        <Col className="display" style={{
                                                            padding: "0px 3px"
                                                        }}>
                                                            <img src={reshared} style={{ height: "22px", width: "25px", marginTop: "-2px" }} />
                                                            <p className="ml_05" style={{
                                                                color: "#fff", fontSize: "10px", marginTop: '3px'
                                                            }}>
                                                              {user?.attributes?.repost_count}
                                                            </p>

                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Grid>
                                                </Grid>


                                            
                                            {
                                              (followingUsers.includes(user?.id)) &&
                                              //user?.attributes?.follow_status == true &&
                                              <Grid item xs={12} sm={2} style={{}} >
                                                <Button test-id="unfollow_user_in_search_test_id" className="follow-butt follow-icon-left follow-button-width" style={{color:'white',padding: '5px 20px',borderRadius: '20px',borderColor: 'rgb(12,13,13)', backgroundColor: 'rgb(12,13,13)', height: '25px', fontSize: '10px', marginLeft: '-10px', marginTop: '10px',textTransform: 'capitalize'}} onClick={() => {unFollowUser(user?.id)}}>UnFollow</Button>
                                              </Grid>
                                            }
                                          {
                                            (!followingUsers.includes(user?.id)) &&
                                             //user?.attributes?.follow_status == false &&
                                            <Grid item xs={12} sm={2} style={{}} >
                                              <Button test-id="follow_user_in_search_test_id" className="follow-butt follow-icon-left follow-button-width" style={{color:'white',padding: '5px 20px',borderRadius: '20px',borderColor: 'red', backgroundColor: 'red', height: '25px', fontSize: '10px', marginLeft: '-10px', marginTop: '10px'}} onClick={() => {followUser(user?.id)}}>Follow</Button>
                                            </Grid>
                                          }
                                          

                      </Grid>

                    <SearchMobileUsers user={user} redirectProfile ={redirectProfile} followingUsers={followingUsers} followUser={followUser} unFollowUser={unFollowUser} />
                      
                       
                     
                    </Grid>
                    
                    </>
                })
                :
                
              (<div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{textAlign: 'center',color: 'grey', fontSize: '20px',fontWeight:'bold', marginTop: '200px'}}>NO USERS FOUND!</div>
              </div>
              )
            }
           </Grid>
           </Grid>
        </Grid>
}
       </>
  )
}

export default SearchUsers
// Customizable Area End